/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   POPUP
   =================================================================== */
.base {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 800;
  padding: 10px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
}
.base .box {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 400px;
  background: #ffffff;
  border-radius: 7px 7px 5px 5px;
  box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.3);
  opacity: 1;
  max-height: 60vh;
  transition: transform 0.3s cubic-bezier(0.16, 1.02, 0.77, 0.95), outline 0.2s ease-in-out;
  animation: boxEnter 0.3s cubic-bezier(0.16, 1.02, 0.77, 0.95);
}
@keyframes boxEnter {
  0% {
    opacity: 0;
    transform: scale(1) translateY(60px);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1) translate(0);
  }
}
.base .box .close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.base .box .close svg {
  fill: #ffffff;
}
.base .box .close:active {
  background-color: rgba(0, 0, 0, 0.1);
}
.base.sizeWrap > .box {
  width: auto;
  max-width: none;
}
.base.level-1 > .box {
  outline: 2px solid rgba(255, 255, 255, 0.25);
}
.base.level-2 {
  backdrop-filter: none;
  background: transparent;
}
.base.level-2 > .box {
  transform: scale(0.98) translateY(-15px);
}
.base.level-3 {
  backdrop-filter: none;
  background: transparent;
}
.base.level-3 > .box {
  transform: scale(0.96) translateY(-30px);
}
.base.level-4 {
  backdrop-filter: none;
  background: transparent;
}
.base.level-4 > .box {
  transform: scale(0.94) translateY(-45px);
}
@media (min-width: 600px) {
  .base.withTabs > .box {
    height: 60vh;
    min-height: 400px;
    width: 580px;
    max-width: 580px;
  }
}
@media (max-height: 1000px) {
  .base > .box {
    max-height: 90vh;
  }
  .base.withTabs > .box {
    height: 80vh;
  }
}
@media (max-width: 599px) {
  .base {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .base > .box {
    width: 100%;
    min-width: auto;
    transform: none;
    max-height: calc(100vh - 20px);
  }
  .base.withTabs {
    align-items: flex-start;
    flex-direction: column;
  }
  .base.withTabs > .box {
    flex-grow: 1;
  }
}