/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   SIDEBAR
   =================================================================== */
.base {
  display: block;
  padding-bottom: 50px;
}