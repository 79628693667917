/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: relative;
}
.base.selected > table {
  box-shadow: 0 0 0 3px #1761a6;
  border-radius: 3px;
}