/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FLAG
   =================================================================== */
.base {
  display: inline-block;
}
.base .image {
  vertical-align: top;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-height: 100%;
  width: 24px;
  border-radius: 3px;
}
.base.muted .image {
  opacity: 0.3;
  filter: grayscale(100%);
}