/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FRONT PAGE
   =================================================================== */
.base {
  display: block;
  max-width: 1000px;
  padding: 30px;
  margin: 0 auto 150px;
  min-height: 100vh;
}