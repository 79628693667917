/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   SIDEBAR USER
   =================================================================== */
.base {
  padding: 20px 25px;
  margin-bottom: 20px;
}
.base .details {
  text-align: left;
}
.base .details .title {
  margin-top: 5px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}
.base .details .description {
  display: inline-block;
  border-radius: 3px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}