/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  display: flex;
  flex-direction: column;
}
.base .item {
  position: relative;
}
.base .item .button {
  background: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.base .item .button svg {
  fill: #ffffff;
}
.base .item .button svg {
  width: 16px;
  height: 16px;
}
.base .item .button:active:enabled {
  background: #ffffff;
}
.base .item .button:active:enabled svg {
  fill: #1761a6;
}
.base .item .button:active:enabled .label {
  opacity: 1;
  transform: translateX(100%);
}
@media (hover: hover) {
  .base .item .button:hover:enabled {
    background: #ffffff;
  }
  .base .item .button:hover:enabled svg {
    fill: #1761a6;
  }
  .base .item .button:hover:enabled .label {
    opacity: 1;
    transform: translateX(100%);
  }
}
.base .item .button.active {
  background: #ffffff;
}
.base .item .button.active svg {
  fill: #1761a6;
}
.base .item .label {
  position: absolute;
  color: #ffffff;
  right: 0;
  top: 0;
  transform: translateX(90%);
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
  opacity: 0;
  white-space: nowrap;
  transition: opacity 0.2s ease, transform 0.2s ease;
}
@media (max-width: 750px) {
  .base .item .label {
    display: none;
  }
}
.base .item .dropzone {
  opacity: 0;
  pointer-events: none;
}
.base .item .content {
  position: absolute;
  background-color: #ffffff;
  border-radius: 3px;
  padding: 0.2em;
  top: 0;
  left: 0;
  width: 200px;
  transform: translateX(-100%);
  pointer-events: none;
  opacity: 0;
  pointer-events: none;
  transition: left 0.2s ease, opacity 0.2s ease;
}
.base .item .content .arrow {
  position: absolute;
  top: 10px;
  right: -8px;
  width: 20px;
  transform: rotate(90deg);
}
.base .item .content.visible {
  left: -1em;
  opacity: 1;
  pointer-events: all;
}
.base .item:disabled {
  opacity: 0.4;
  cursor: default;
}