/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.base .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: rgba(58, 77, 96, 0.5);
  line-height: 1;
}