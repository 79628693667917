/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   TEXT
   =================================================================== */
.base {
  display: inline;
  vertical-align: middle;
}

.block {
  display: block;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.small {
  font-size: 12px;
}

.normal {
  font-size: 14px;
}

.medium {
  font-size: 16px;
}

.large {
  font-size: 18px;
}

.xlarge {
  font-size: 24px;
}

.weightRegular {
  font-weight: 400 !important;
}

.weightSemiBold {
  font-weight: 600;
}

.weightBold {
  font-weight: 700;
}

.base80 {
  color: rgba(58, 77, 96, 0.8);
}

.base60 {
  color: rgba(58, 77, 96, 0.6);
}

.base40 {
  color: rgba(58, 77, 96, 0.4);
}

.base20 {
  color: rgba(58, 77, 96, 0.2);
}

.success {
  color: #36b189;
}

.warning {
  color: #ffb822;
}

.error {
  color: #d04b67;
}

.primary {
  color: #1761a6;
}

.info {
  color: #4aa1db;
}

.lineThrough {
  text-decoration: line-through;
}

.italic {
  font-style: italic;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

.elementSmall {
  line-height: 31px;
}

.elementNormal {
  line-height: 42px;
}

.spacingSmall {
  margin-bottom: 5px;
}

.lineHeightSmall {
  line-height: 1;
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}