/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   BADGE
   =================================================================== */
.base {
  display: inline-flex;
  align-items: center;
  height: 22px;
  line-height: 22px;
  padding: 0 7px;
  font-size: 11px;
  box-sizing: border-box;
  border: 1px solid rgba(58, 77, 96, 0.3);
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 3px;
  color: rgba(58, 77, 96, 0.8);
  white-space: nowrap;
  column-gap: 2px;
  /* Badge variants
  ---------------------------------------------------------------------- */
  /* Icon color
  ---------------------------------------------------------------------- */
  /* Number
  ---------------------------------------------------------------------- */
  /* Size
  ---------------------------------------------------------------------- */
  /* Spacing
  ---------------------------------------------------------------------- */
  /* Placement
  ---------------------------------------------------------------------- */
}
.base .label {
  padding-top: 2px;
  line-height: normal;
  white-space: nowrap;
}
.base .icon svg {
  width: 12px;
  height: 12px;
}
.base .icon svg {
  fill: rgba(58, 77, 96, 0.8);
}
.base .dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 12px;
}
.base.fill {
  background-color: #3a4d60;
  border-color: #3a4d60;
  color: #ffffff;
}
.base.fill .icon svg {
  fill: #ffffff;
}
.base.clickable {
  cursor: pointer;
}
.base.primary {
  border-color: #1761a6;
  background-color: transparent;
  color: #1761a6;
}
.base.primary .icon svg {
  fill: #1761a6;
}
.base.primary.fill {
  background-color: #1761a6;
  color: #ffffff;
}
.base.primary.fill .icon svg {
  fill: #ffffff;
}
.base.contrast {
  border-color: #ffffff;
  background-color: transparent;
  color: #ffffff;
}
.base.contrast .icon svg {
  fill: #ffffff;
}
.base.contrast.fill {
  background-color: #ffffff;
  color: #1761a6;
}
.base.silent {
  border-color: rgba(58, 77, 96, 0.1);
  background-color: transparent;
  color: rgba(58, 77, 96, 0.4);
}
.base.silent .icon svg {
  fill: rgba(58, 77, 96, 0.4);
}
.base.silent.fill {
  border-color: transparent;
  background-color: rgba(58, 77, 96, 0.1);
  color: rgba(58, 77, 96, 0.4);
}
.base.silent.fill .icon svg {
  fill: rgba(58, 77, 96, 0.4);
}
.base.info {
  border-color: #4aa1db;
  background-color: transparent;
  color: #4aa1db;
}
.base.info .icon svg {
  fill: #4aa1db;
}
.base.info.fill {
  background-color: #4aa1db;
  color: #ffffff;
}
.base.info.fill .icon svg {
  fill: #ffffff;
}
.base.error {
  border-color: #d04b67;
  background-color: transparent;
  color: #d04b67;
}
.base.error .icon svg {
  fill: #d04b67;
}
.base.error.fill {
  background-color: #d04b67;
  color: #ffffff;
}
.base.error.fill .icon svg {
  fill: #ffffff;
}
.base.warning {
  border-color: #ffb822;
  background-color: transparent;
  color: #ffb822;
}
.base.warning .icon svg {
  fill: #ffb822;
}
.base.warning.fill {
  background-color: #ffb822;
  color: #ffffff;
}
.base.warning.fill .icon svg {
  fill: #ffffff;
}
.base.success {
  border-color: #36b189;
  background-color: transparent;
  color: #36b189;
}
.base.success .icon svg {
  fill: #36b189;
}
.base.success.fill {
  background-color: #36b189;
  color: #ffffff;
}
.base.success.fill .icon svg {
  fill: #ffffff;
}
.base.rankLow {
  border-color: #47c680;
  background-color: transparent;
  color: #47c680;
}
.base.rankLow .icon svg {
  fill: #47c680;
}
.base.rankLow.fill {
  background-color: #47c680;
  color: #ffffff;
}
.base.rankLow.fill .icon svg {
  fill: #ffffff;
}
.base.rankMid {
  border-color: #ff9100;
  background-color: transparent;
  color: #ff9100;
}
.base.rankMid .icon svg {
  fill: #ff9100;
}
.base.rankMid.fill {
  background-color: #ff9100;
  color: #ffffff;
}
.base.rankMid.fill .icon svg {
  fill: #ffffff;
}
.base.rankHigh {
  border-color: #e02447;
  background-color: transparent;
  color: #e02447;
}
.base.rankHigh .icon svg {
  fill: #e02447;
}
.base.rankHigh.fill {
  background-color: #e02447;
  color: #ffffff;
}
.base.rankHigh.fill .icon svg {
  fill: #ffffff;
}
.base.attention {
  border-color: #f6fd9a;
  background-color: transparent;
  color: #3a4d60;
}
.base.attention .icon svg {
  fill: #f6fd9a;
}
.base.attention.fill {
  background-color: #f6fd9a;
  color: rgba(0, 0, 0, 0.9);
}
.base.attention.fill .icon svg {
  fill: #3a4d60;
}
.base.iconError .icon svg {
  fill: #d04b67;
}
.base.iconInfo .icon svg {
  fill: #4aa1db;
}
.base.number {
  font-size: 13px;
  border-radius: 22px;
  min-width: 22px;
  text-align: center;
  text-transform: none;
}
.base.number .label {
  padding-top: 0;
}
.base.small {
  height: 16px;
  border-radius: 2px;
  font-size: 9px;
  padding: 0 4px;
}
.base.large {
  height: 28px;
  padding: 0 10px;
  font-size: 14px;
}
.base.leftSpacing {
  margin-left: 5px;
}
.base.rightSpacing {
  margin-right: 5px;
}
.base.listSpacing {
  margin: 0 3px 3px 0;
}
.base.stripped {
  border: none;
  padding-left: 0;
}
.base.placeTopRight {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(130%, -40%);
}