/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   PAGE BAR
   =================================================================== */
.base {
  position: fixed;
  height: 64px;
  bottom: 0;
  left: 280px;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 -1px 10px 1px rgba(58, 77, 96, 0.08);
  z-index: 400;
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.base .wrapper {
  flex-grow: 1;
  white-space: nowrap;
}
@media (max-width: 899px) {
  .base {
    left: 0;
  }
}