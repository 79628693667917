/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.h4 {
  font-size: 20px;
  font-weight: 300;
  margin: 0 0 20px 0;
}

.marginNone {
  margin: 0;
}