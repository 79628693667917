/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 3px;
  width: 42px;
  height: 42px;
  background-color: rgba(23, 97, 166, 0.07);
  vertical-align: top;
  /* CLICKABLE
  --------------------------------------------- */
  /* SIZES
  --------------------------------------------- */
  /* STYLES
  --------------------------------------------- */
  /* ICON COLORS
  --------------------------------------------- */
}
.base.hasSource {
  background: none;
}
.base.actualLoading {
  aspect-ratio: 16/9;
}
.base .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  overflow: hidden;
}
.base .wrapper .busy {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
  background-color: rgba(58, 77, 96, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
}
.base .wrapper .busy .spinner {
  width: 30%;
  max-width: 50px;
  min-width: 10px;
}
.base .wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.base .wrapper .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 80px;
  max-height: 80px;
}
.base .wrapper .icon svg {
  width: 100%;
  height: 100%;
}
.base .wrapper .icon svg {
  fill: rgba(58, 77, 96, 0.2);
}
.base .wrapper .figure {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.base .wrapper .figure svg {
  width: 100%;
  height: 100%;
}
.base .wrapper .figure svg {
  fill: rgba(58, 77, 96, 0.2);
}
.base .remove {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 2;
  padding: 3px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgba(58, 77, 96, 0.8);
  border-radius: 100px;
}
.base .remove .icon svg {
  fill: #ffffff;
}
.base .remove .icon svg {
  width: 14px;
  height: 14px;
}
.base .remove:hover {
  background: #d04b67;
}
.base .remove:hover .icon svg {
  fill: #ffffff;
}
.base .dropzone {
  display: none;
}
.base.clickable {
  cursor: pointer;
}
.base.dashed {
  border: 1px dashed rgba(58, 77, 96, 0.4);
}
.base.dashed:hover {
  border-color: rgba(23, 97, 166, 0.8);
}
.base.small {
  width: 31px;
  height: 31px;
}
.base.medium {
  width: 60px;
  height: 60px;
}
.base.large {
  width: 80px;
  height: 80px;
}
.base.xlarge {
  width: 200px;
  height: 200px;
}
.base.fill {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
}
.base.fill .wrapper {
  position: absolute;
}
.base.fit {
  width: 100%;
  height: 100%;
}
.base.actual {
  display: block;
  width: auto;
  height: auto;
}
.base.actual .wrapper {
  position: static;
}
.base.actual .wrapper img {
  vertical-align: top;
  display: inline-block;
  max-width: 100%;
}
.base.actual.framed .wrapper {
  margin: 4px;
}
.base.framed {
  background-color: #ffffff;
  border: 1px solid rgba(58, 77, 96, 0.25);
}
.base.framed .wrapper {
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border-radius: 2px;
}
.base.framed .wrapper .icon svg {
  fill: rgba(58, 77, 96, 0.1);
}
.base.framed.medium .wrapper {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
}
.base.rounded {
  border-radius: 50%;
}
.base.rounded .wrapper {
  border-radius: 50%;
}
.base.squared {
  border-radius: 0;
}
.base.squared .squared {
  border-radius: 0;
}
.base.grayscale .wrapper img {
  filter: grayscale(100%);
}
.base.iconPrimary .wrapper .icon svg {
  fill: #1761a6;
}
.base.iconPrimary .wrapper .figure svg {
  fill: #1761a6;
}
.base.iconSuccess .wrapper .icon svg {
  fill: #36b189;
}
.base.iconSuccess .wrapper .figure svg {
  fill: #36b189;
}
.base.iconWarning .wrapper .icon svg {
  fill: #ffb822;
}
.base.iconWarning .wrapper .figure svg {
  fill: #ffb822;
}
.base.iconError .wrapper .icon svg {
  fill: #d04b67;
}
.base.iconError .wrapper .figure svg {
  fill: #d04b67;
}