/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  border: 1px solid rgba(58, 77, 96, 0.25);
  color: #3a4d60;
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  padding: 20px;
}
.base:hover {
  border-color: #1761a6;
  box-shadow: inset 0 0 0 1px #1761a6;
}
.base:active {
  border-color: rgba(58, 77, 96, 0.1);
  background-color: #f2f2f2;
}
.base .symbol {
  margin: 0 25px 10px;
  aspect-ratio: 1/1;
}
.base .label {
  font-size: 16px;
  height: 75px;
  display: flex;
  align-items: center;
  text-align: center;
}