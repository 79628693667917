/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   BUTTON COLUMN
   =================================================================== */
.base .buttonWrapper {
  display: block;
  text-align: center;
  margin-bottom: 5px;
}
.base .buttonWrapper:last-child {
  margin-bottom: 0;
}
@media (max-width: 599px) {
  .base {
    display: block;
  }
  .base .buttonWrapper .button {
    display: block;
    width: 100%;
  }
}