/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FRONT BAR
   =================================================================== */
.base {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  z-index: 500;
}
.base .logo {
  display: inline-block;
  margin: 20px;
  padding: 5px 0;
}
.base .nav {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}
.base .nav .toggle {
  padding: 10px;
  margin: -5px -5px 0 0;
  display: none;
  cursor: pointer;
}
.base .nav .toggle svg {
  fill: #1761a6;
}
.base .nav .toggle svg {
  width: 20px;
  height: 20px;
}
.base .nav .menu {
  position: relative;
}
.base .nav .menu .logo {
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  display: none;
}
.base .nav .menu .close {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  padding: 25px;
}
.base .nav .menu .close svg {
  width: 20px;
  height: 20px;
}
.base .nav .menu .close svg {
  fill: #1761a6;
}
.base .nav .menu .wrapper {
  display: flex;
}
.base .nav .menu .wrapper .item {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 50px;
  transition: all 0.2s ease;
  cursor: pointer;
}
.base .nav .menu .wrapper .item:hover {
  color: #1761a6;
}
.base .nav .menu .wrapper .item.variant {
  padding: 10px 25px;
  background-color: #1761a6;
  color: #ffffff;
  margin-left: 10px;
}
.base .nav .menu .wrapper .item.variant:hover {
  color: #ffffff;
  background-color: #1761a6;
}
.base .nav .menu .wrapper .item.active {
  text-decoration: underline;
  text-decoration-color: #1761a6;
  text-underline-offset: 6px;
  text-decoration-thickness: 2px;
}
.base .nav .menu .wrapper .item.active.variant {
  text-decoration: none;
  color: #3a4d60;
  cursor: default;
  background-color: rgba(23, 97, 166, 0.07);
}
.base.colorContrast .nav .menu .wrapper .item {
  color: #ffffff;
}
.base.colorContrast .nav .menu .wrapper .item.variant {
  background-color: #ffffff;
  color: #3a4d60;
}
.base.colorContrast .nav .menu .wrapper .item.active {
  text-decoration-color: #ffffff;
}
@media (max-width: 599px) {
  .base .nav .toggle {
    display: block;
  }
  .base .nav .menu {
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 100vw;
    width: 100%;
    height: 100%;
    background: #1761a6;
    z-index: 700;
    transition: left 0.3s ease-in-out;
  }
  .base .nav .menu .logo {
    display: block;
  }
  .base .nav .menu .close {
    display: block;
  }
  .base .nav .menu .close svg {
    fill: #ffffff;
  }
  .base .nav .menu .wrapper {
    width: 100%;
    margin: 0 30px;
    flex-direction: column-reverse;
    row-gap: 15px;
  }
  .base .nav .menu .wrapper .item,
.base .nav .menu .wrapper .item.variant {
    background: rgba(0, 0, 0, 0.2);
    display: block;
    font-size: 26px;
    font-weight: 300;
    color: #ffffff;
    text-align: center;
    padding: 15px 25px;
    text-decoration: none;
    margin: 0;
  }
  .base .nav .menu .wrapper .item.active,
.base .nav .menu .wrapper .item.variant.active {
    text-decoration: none;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
  }
  .base .nav .menu.visible {
    left: 0;
  }
}
@media (max-width: 500px), (max-height: 960px) {
  .base.courseMode {
    display: none;
  }
}