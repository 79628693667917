/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FULLSCREEN
   =================================================================== */
.base {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 600;
  background-color: #eaf0f5;
}
.base .titlebar {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background-color: #ffffff;
  border-left: 4px solid #1761a6;
  box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.08);
}
.base .titlebar .title {
  height: 64px;
  line-height: 64px;
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  padding: 0 10px;
}
.base .titlebar .buttons {
  padding: 11px;
}
.base .content {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  overflow: auto;
}