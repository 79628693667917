/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   BAR PANE
   =================================================================== */
.base {
  display: block;
  height: 64px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 400;
}
@media (min-width: 900px) {
  .base.onlyPhone {
    left: 280px;
  }
  .base.onlyPhone .mainBar {
    display: none;
  }
  .base.onlyPhone .userBar {
    top: 0;
  }
}

/* USER BAR
------------------------------------------- */
.userBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background-color: #ffffff;
  display: flex;
  transition: top 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.userBar .left,
.userBar .right {
  width: 50%;
  height: 64px;
}
.userBar .left {
  display: flex;
  align-items: center;
}
.userBar .right {
  display: flex;
  justify-content: flex-end;
}
.userBar.visible {
  box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.08);
  top: 64px;
}
@media (max-width: 899px) {
  .userBar.visible {
    box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.2);
  }
}

/* MAIN BAR
------------------------------------------- */
.mainBar {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background: #1761a6;
}
.mainBar .left {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  height: 64px;
}
.mainBar .center {
  width: 100%;
  padding: 0 64px;
}
.mainBar .center .text {
  line-height: 64px;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mainBar .center .progress {
  height: 64px;
  padding: 0 5px;
}
.mainBar .center .progress .bar {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  max-width: 400px;
  border-radius: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.mainBar .center .progress .bar .trail {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  height: 10px;
  background-color: #ffffff;
  transition: width 0.5s ease;
}
.mainBar .right {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  height: 64px;
}

.meMenu {
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.iconButton {
  display: flex;
  position: relative;
  height: 64px;
  padding: 0 15px;
  cursor: pointer;
  align-items: center;
}
.iconButton .icon {
  transition: transform 0.2s ease-in-out;
}
.iconButton .icon svg {
  fill: #ffffff;
}
.iconButton:active, .iconButton:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
.iconButton.active .icon {
  transform: rotate(60deg);
}

.menuButton {
  position: relative;
  height: 64px;
  padding: 0 15px;
  cursor: pointer;
}
.menuButton .icon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.menuButton .icon svg {
  fill: #ffffff;
}
.menuButton:active, .menuButton:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

/* VESSEL LIST
------------------------------------------- */
/* ICON MENU
------------------------------------------- */
.iconMenu {
  display: flex;
}
.iconMenu .item {
  height: 100%;
  display: flex;
  align-items: center;
}
.iconMenu .item .circle {
  cursor: pointer;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.iconMenu .item .circle svg {
  fill: #1761a6;
}
.iconMenu .item .circle svg {
  width: 20px;
  height: 20px;
}
.iconMenu .item:hover .circle {
  border: 1px solid rgba(23, 97, 166, 0.2);
}
.iconMenu .item:hover .circle svg {
  fill: #145490;
}
.iconMenu .item:active .circle {
  background: #1761a6;
}
.iconMenu .item:active .circle svg {
  fill: #ffffff;
}