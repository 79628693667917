/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: relative;
  top: 0;
  left: 0;
  width: 280px;
  position: sticky;
  height: calc(100vh - 64px);
  top: 64px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 300;
  box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.1);
  background: #ffffff;
  border-top: 1px solid rgba(58, 77, 96, 0.05);
}
.base .content {
  height: 100%;
  overflow-y: auto;
}
.base .handle {
  display: none;
  position: absolute;
  top: 0;
  right: -20px;
  width: 20px;
  bottom: 0;
  transition: opacity 0.2s ease-in-out;
}
.base .handle .flip {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background-color: #ffffff;
  height: 200px;
  width: 28px;
  border-radius: 0 100px 100px 0;
  box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
}
.base .overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  top: 0;
  left: 100%;
  bottom: 0;
  width: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}
@media (max-width: 1199px) {
  .base {
    transform: translateX(-100%);
    box-shadow: none;
    transition: transform 0.2s ease-in-out;
  }
  .base .handle {
    display: block;
  }
  .base.visible {
    transform: translate(0);
    box-shadow: 0 5px 25px 1px rgba(58, 77, 96, 0.3);
  }
  .base.visible .handle {
    opacity: 0;
  }
  .base.slideOut {
    animation: slide-out 0.5s ease-in-out forwards;
  }
}
@media (max-width: 599px) {
  .base .handle .flip {
    width: 22px;
  }
  .base.visible .overlay {
    width: calc(100vw - 100%);
    opacity: 1;
    pointer-events: all;
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}