/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   TABS
   =================================================================== */
.base {
  display: flex;
  flex-direction: column;
}
.base .tabs {
  position: relative;
  height: 42px;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid rgba(58, 77, 96, 0.15);
  flex-shrink: 0;
}
.base .tabs .more {
  display: none;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  z-index: 3;
  align-items: center;
}
@media (max-width: 599px) {
  .base .tabs .more {
    display: flex;
  }
}
.base .tabs .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  min-width: 100%;
}
.base .tabs .wrapper .tab {
  cursor: pointer;
  height: 42px;
  padding: 0 20px;
  user-select: none;
  color: rgba(58, 77, 96, 0.8);
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
  max-width: 180px;
}
.base .tabs .wrapper .tab .icon {
  display: flex;
  align-items: center;
  padding-right: 10px;
  flex-shrink: 0;
}
.base .tabs .wrapper .tab .icon svg {
  fill: rgba(58, 77, 96, 0.2);
}
.base .tabs .wrapper .tab .label {
  display: flex;
  align-items: center;
  padding-top: 2px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.base .tabs .wrapper .tab .counter {
  display: flex;
  align-items: center;
  padding-left: 7px;
}
.base .tabs .wrapper .tab .counter .numbers {
  display: block;
  min-width: 18px;
  line-height: 1;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  color: rgba(58, 77, 96, 0.6);
  background-color: rgba(58, 77, 96, 0.05);
  padding: 3px 5px;
  border-radius: 20px;
}
.base .tabs .wrapper .tab:hover {
  color: rgba(58, 77, 96, 0.8);
}
.base .tabs .wrapper .tab:hover .icon svg {
  fill: rgba(58, 77, 96, 0.3);
}
.base .tabs .wrapper .tab.active {
  cursor: default;
  border-bottom-color: #1d7bd3;
  color: #1761a6;
}
.base .tabs .wrapper .tab.active .icon svg {
  fill: #1761a6;
}
.base .tabs .wrapper .tab.active .counter .numbers {
  color: #3a4d60;
  background-color: rgba(58, 77, 96, 0.1);
}
.base .tabs .fadeLeft,
.base .tabs .fadeRight {
  position: absolute;
  top: 0;
  bottom: 2px;
  width: 20px;
  pointer-events: none;
  z-index: 2;
}
.base .tabs .fadeLeft {
  left: 0;
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.base .tabs .fadeRight {
  right: 0;
  background: linear-gradient(-90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.base .pane {
  margin-top: 20px;
  max-width: 100%;
}
@media (max-width: 599px) {
  .base.overflow .tabs {
    padding-right: 40px;
  }
  .base.overflow .fadeRight {
    right: 40px;
  }
}
.base.popupMode {
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  height: 100%;
}
.base.popupMode .paneWrapper {
  flex-grow: 1;
  min-height: 0;
}
.base.popupMode .paneWrapper .pane {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  height: 100%;
}
@media (max-width: 599px) {
  .base.popupMode .tabs {
    height: 45px;
  }
  .base.popupMode .tabs .tab {
    height: 44px;
    padding-top: 2px;
  }
}
@media (min-width: 600px) {
  .base.popupMode {
    flex-direction: row;
  }
  .base.popupMode .tabs {
    width: 180px;
    min-width: 180px;
    border-right: 1px solid rgba(58, 77, 96, 0.1);
    background-color: rgba(23, 97, 166, 0.07);
    border-bottom: none;
    height: 100%;
  }
  .base.popupMode .tabs .wrapper {
    display: flex;
    position: static;
    flex-direction: column;
    border-bottom: 0;
    padding-top: 0;
  }
  .base.popupMode .tabs .fadeLeft,
.base.popupMode .tabs .fadeRight {
    display: none;
  }
  .base.popupMode .tabs .tab {
    border-left: 3px solid transparent;
    border-bottom: 1px solid rgba(58, 77, 96, 0.05);
    padding: 0 10px;
  }
  .base.popupMode .tabs .tab.active {
    border-left-color: #1d7bd3;
    border-bottom-color: rgba(58, 77, 96, 0.1);
    background-color: white;
  }
  .base.popupMode .paneWrapper {
    position: relative;
    flex-grow: 1;
    max-width: 400px;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }
}
.base.boxMode .paneWrapper .pane {
  margin-top: 0;
}