/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   TABLE ROW
   =================================================================== */
.row > .col {
  padding: 0;
  border-top: 1px solid rgba(58, 77, 96, 0.1);
  border-right: 1px solid rgba(58, 77, 96, 0.05);
}
.row > .col:last-child {
  border-right: none;
}
.row.clickable {
  cursor: pointer;
}
.row.clickable:not(.disabled):hover > .col {
  background: rgba(58, 77, 96, 0.03);
}
.row.blink > .col {
  animation: attentionBlink 1s ease-in-out none infinite;
}
@keyframes attentionBlink {
  0% {
    background-color: rgba(246, 253, 154, 0.2);
  }
  50% {
    background-color: rgba(246, 253, 154, 0.6);
  }
  100% {
    background-color: rgba(246, 253, 154, 0.2);
  }
}
.row.disabled {
  cursor: default;
}
.row.disabled > .col {
  background-color: rgba(255, 255, 255, 0.35);
  opacity: 0.4;
}