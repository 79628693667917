/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: relative;
}

.bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 400;
  height: 64px;
  background-color: #145490;
}
.bar .logo {
  height: 64px;
  display: flex;
  align-items: center;
}

.center {
  position: relative;
  background-color: #1761a6;
  margin-top: 64px;
}

.menu {
  background-color: #ffffff;
}

.user {
  position: relative;
  display: flex;
  z-index: 2;
  padding: 20px 0;
}
.user .picture {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(58, 77, 96, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 3px solid #ffffff;
  flex-shrink: 0;
}
.user .picture .icon svg {
  width: 60px;
  height: 60px;
}
.user .picture .icon svg {
  fill: #1761a6;
}
.user .picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.user .picture .shine {
  position: absolute;
  top: -170%;
  left: 0;
  width: 40%;
  height: 200%;
  transform: rotate(-55deg) translateX(-120%);
  transform-origin: 0 0;
  background-color: rgba(255, 255, 255, 0.3);
  animation: shineAnimation 15s infinite cubic-bezier(0.195, 0.855, 0.7, 0.865);
  filter: blur(2px);
}
@keyframes shineAnimation {
  0% {
    width: 10%;
    opacity: 1;
    top: -170%;
  }
  3% {
    width: 40%;
  }
  6% {
    width: 10%;
    opacity: 1;
    top: 100%;
  }
  7% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.user .details {
  position: relative;
  padding: 0 20px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user .details .greeting {
  font-size: 20px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}
.user .details .name {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 599px) {
  .user {
    padding: 15px 0;
  }
  .user .picture {
    width: 80px;
    height: 80px;
  }
  .user .details .greeting {
    font-size: 16px;
    margin-bottom: 3px;
  }
  .user .details .name {
    font-size: 24px;
    line-height: 24px;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}
.background .waves {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10vh;
  min-height: 50px;
  max-height: 70px;
}
.background .waves .parallax > use {
  animation: move-forever 50s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.background .waves .parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 10s;
  fill: rgba(23, 97, 166, 0.4);
}
.background .waves .parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 16s;
  fill: rgba(23, 97, 166, 0.8);
}
.background .waves .parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 20s;
  fill: #165ea2;
}
.background .waves .parallax > use:nth-child(4) {
  animation-delay: -10s;
  animation-duration: 30s;
  fill: #145490;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
@media (max-width: 768px) {
  .background .waves .waves {
    height: 40px;
    min-height: 40px;
  }
  .background .waves .content {
    height: 30vh;
  }
  .background .waves h1 {
    font-size: 24px;
  }
}