/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   BUTTON
   =================================================================== */
.base {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background: none;
  height: 42px;
  font-family: "Catamaran", sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
  cursor: pointer;
  border: 1px solid #1761a6;
  text-decoration: none;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  border-radius: 5px;
  white-space: nowrap;
}
.base .label {
  user-select: none;
  padding-top: 2px;
}
.base:focus {
  outline: 2px solid rgba(23, 97, 166, 0.2);
}
.base:active {
  transition: background-color 0s ease;
  outline: none;
}

.base[disabled],
.base[disabled]:hover,
.base[disabled]:active {
  border-color: transparent !important;
  background-color: rgba(23, 97, 166, 0.05);
  color: rgba(58, 77, 96, 0.3);
  box-shadow: none;
  cursor: not-allowed;
}
.base[disabled] .icon svg,
.base[disabled]:hover .icon svg,
.base[disabled]:active .icon svg {
  fill: rgba(58, 77, 96, 0.2) !important;
}
.base[disabled]:hover, .base[disabled]:focus,
.base[disabled]:hover:hover,
.base[disabled]:hover:focus,
.base[disabled]:active:hover,
.base[disabled]:active:focus {
  color: rgba(58, 77, 96, 0.3);
}

.base.active, .base.active:hover, .base.active:active {
  background-color: rgba(23, 97, 166, 0.15);
  border-color: rgba(58, 77, 96, 0.1) !important;
  color: #3a4d60;
  transition: background-color 0s ease;
}
.base.active .icon svg, .base.active:hover .icon svg, .base.active:active .icon svg {
  fill: #3a4d60;
}

/* ===================================================================
   LOADER (Busy)
   =================================================================== */
.base.busy {
  cursor: default !important;
  background-color: rgba(23, 97, 166, 0.07);
}
.base.busy .icon,
.base.busy .label {
  opacity: 0;
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.loader.spinner {
  padding: 0.5em;
}

/* ===================================================================
   BUTTON FLAG
   =================================================================== */
.base .flag {
  margin-right: 8px;
}

/* ===================================================================
   BUTTON ICON
   =================================================================== */
.base .icon {
  vertical-align: 1px;
  margin-right: 6px;
  margin-left: -4px;
}
.base.iconRight .icon {
  margin-right: 0;
  margin-left: 5px;
}
.base.iconPrimary .icon svg {
  fill: #1761a6;
}
.base.iconWarning .icon svg {
  fill: #ffb822;
}
.base.iconSuccess .icon svg {
  fill: #36b189;
}
.base.iconError .icon svg {
  fill: #d04b67;
}
.base.hideIcon .icon {
  display: none !important;
}

/* ===================================================================
   BUTTON COLORS
   =================================================================== */
.colorDefault {
  color: #3a4d60;
  border-color: rgba(58, 77, 96, 0.2);
  background-color: #ffffff;
}
.colorDefault .icon svg {
  fill: #3a4d60;
}
.colorDefault:hover, .colorDefault:focus {
  border-color: #1761a6;
  color: #3a4d60;
}
.colorDefault:active {
  border-color: rgba(58, 77, 96, 0.1);
  background-color: rgba(23, 97, 166, 0.07);
}

.colorPrimary {
  color: #ffffff;
  border-color: transparent;
  background-color: #1761a6;
}
.colorPrimary .icon svg {
  fill: #ffffff;
}
.colorPrimary:hover, .colorPrimary:focus {
  border-color: transparent;
  background-color: #1a6ebc;
  color: #ffffff;
}
.colorPrimary:active {
  background-color: #145490;
}

.colorSuccess {
  color: #ffffff;
  border-color: transparent;
  background-color: #36b189;
}
.colorSuccess .icon svg {
  fill: #ffffff;
}
.colorSuccess:hover, .colorSuccess:focus {
  border-color: transparent;
  background-color: #3dc498;
  color: #ffffff;
  outline-color: rgba(54, 177, 137, 0.3);
}
.colorSuccess:active {
  background-color: #309d7a;
}

.colorError {
  color: #ffffff;
  border-color: transparent;
  background-color: #d04b67;
}
.colorError .icon svg {
  fill: #ffffff;
}
.colorError:hover, .colorError:focus {
  border-color: transparent;
  background-color: #d55f78;
  color: #ffffff;
  outline-color: rgba(208, 75, 103, 0.3);
}
.colorError:active {
  background-color: #cb3756;
}

.colorLight {
  border-color: transparent;
  background: transparent;
  color: rgba(255, 255, 255, 0.75);
}
.colorLight .icon svg {
  fill: rgba(255, 255, 255, 0.8);
}
.colorLight:hover, .colorLight:focus {
  box-shadow: none;
  background: transparent;
  color: #ffffff;
  border-color: transparent;
}
.colorLight:hover .icon svg, .colorLight:focus .icon svg {
  fill: #ffffff;
}
.colorLight:active {
  box-shadow: none;
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
}

.colorSilent {
  border-color: transparent;
  background: rgba(23, 97, 166, 0.07);
  color: #3a4d60;
}
.colorSilent .icon svg {
  fill: rgba(58, 77, 96, 0.5);
}
.colorSilent:hover, .colorSilent:focus {
  box-shadow: none;
  border-color: transparent;
  background: rgba(23, 97, 166, 0.15);
  color: #3a4d60;
}
.colorSilent:active {
  box-shadow: none;
  border-color: transparent;
  background: rgba(23, 97, 166, 0.07);
  color: #3a4d60;
}

.colorSilentError {
  border-color: rgba(208, 75, 103, 0.4);
  border-style: dashed;
  color: #d04b67;
}
.colorSilentError .icon svg {
  fill: #d04b67;
}
.colorSilentError:hover, .colorSilentError:focus {
  border-color: #d04b67;
  border-style: solid;
  color: #d04b67;
  outline-color: rgba(208, 75, 103, 0.2);
}
.colorSilentError:active {
  background-color: rgba(208, 75, 103, 0.05);
}

.colorToolbar {
  border-radius: 0;
  border-color: rgba(255, 255, 255, 0.1);
  background-color: #3a4d60;
  backdrop-filter: blur(3px);
  border-width: 0 !important;
}
.colorToolbar .icon svg {
  fill: #ffffff;
}
.colorToolbar:not(:last-child) {
  border-right-width: 1px !important;
}
.colorToolbar:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.colorToolbar:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.colorToolbar:hover, .colorToolbar:focus {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: #445a70;
}
.colorToolbar:active, .colorToolbar.active, .colorToolbar.active:hover, .colorToolbar.active:active {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: #4a99e2;
}
.colorToolbar:active .icon svg, .colorToolbar.active .icon svg, .colorToolbar.active:hover .icon svg, .colorToolbar.active:active .icon svg {
  fill: #ffffff;
}

/* ===================================================================
   SIZES
   =================================================================== */
.sizeBig {
  padding: 0 50px;
  height: 50px;
  line-height: 48px;
  font-size: 16px;
}

.sizeSmall {
  padding: 0 15px;
  height: 31px;
  line-height: 31px;
}
.sizeSmall .icon svg {
  width: 14px;
  height: 14px;
}

/* ===================================================================
   BUTTON MUTTATORS
   =================================================================== */
.fill {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ===================================================================
   SHAPES
   =================================================================== */
.circle {
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%;
  width: 42px;
  min-width: 42px;
}
.circle .icon {
  margin: 0;
}
.circle.sizeSmall {
  width: 31px;
  min-width: 31px;
}
.circle.sizeBig {
  width: 50px;
  min-width: 50px;
}

.square {
  padding-left: 0;
  padding-right: 0;
  width: 42px;
}
.square .icon {
  margin: 0;
}
.square.sizeSmall {
  width: 31px;
  min-width: 31px;
}
.square.sizeBig {
  width: 50px;
  min-width: 50px;
}
.square .flag {
  margin-right: 0;
}

.pill {
  border-radius: 100px;
}