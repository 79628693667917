/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  display: block;
  background-color: #eaf0f5;
  overflow-y: visible;
  flex-grow: 1;
  padding-top: 64px;
  max-width: calc(100vw - (100vw - 100%));
}
.base .disclaimer {
  padding: 20px 30px 20px;
}
@media (max-width: 599px) {
  .base .disclaimer {
    padding: 15px 15px;
  }
}
.base.noBar {
  padding-top: 0;
}
@media (min-width: 900px) {
  .base.hasSidebar {
    max-width: calc(100vw - (100vw - 100%) - 280px);
  }
}
@media (max-width: 899px) {
  .base {
    max-width: calc(100vw - (100vw - 100%));
  }
}