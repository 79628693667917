/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   GRID ROW
   =================================================================== */
.row {
  display: flex;
  flex-wrap: wrap;
  min-width: 0;
}
.row.gutter_ver_tiny {
  margin-left: calc(0.25rem / (-2));
  margin-right: calc(0.25rem / (-2));
}
.row.gutter_ver_small {
  margin-left: calc(10px / (-2));
  margin-right: calc(10px / (-2));
}
.row.gutter_hor_tiny {
  margin-top: calc(0.25rem * (-1));
}
.row.gutter_hor_small {
  margin-top: calc(10px * (-1));
}
.row.gutter_ver_regular {
  margin-left: calc(20px / (-2));
  margin-right: calc(20px / (-2));
}
.row.gutter_hor_regular {
  margin-top: calc(20px * (-1));
}
.row.gutter_ver_large {
  margin-left: calc(30px / (-2));
  margin-right: calc(30px / (-2));
}
.row.gutter_hor_large {
  margin-top: calc(30px * (-1));
}
.row.spacing_small {
  margin-bottom: 10px;
}
.row.spacing_regular {
  margin-bottom: 20px;
}
.row.spacing_large {
  margin-bottom: 30px;
}
.row.wrap_none {
  flex-wrap: nowrap;
}