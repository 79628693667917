/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   SPINNER
   =================================================================== */
.base {
  position: relative;
  margin: 0 auto;
  width: 42px;
  max-width: 80px;
}
.base:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.base .circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.base .path {
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: rgba(58, 77, 96, 0.2);
  stroke-width: 4px;
}
.base .trail {
  stroke: #ffffff;
  stroke-width: 3px;
}

.sizeSmall {
  width: 31px;
}

.sizeLarge {
  width: 80px;
  stroke-width: 5px;
}

.colorPrimary .path {
  stroke: #1761a6;
}

.colorContrast .path {
  stroke: #ffffff;
}
.colorContrast .trail {
  stroke: rgba(0, 0, 0, 0.2);
}

.colorError .path {
  stroke: #d04b67;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}