/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   ITEM LIST
   =================================================================== */
.base.hidden {
  display: none;
}

.list {
  display: block;
  position: relative;
  margin: -0.5em 0;
}

.loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 3;
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
}
.loader.empty {
  position: static;
  min-height: 54px;
}

.heading {
  display: flex;
  text-align: left;
  border-bottom: 1px solid rgba(58, 77, 96, 0.15);
  font-weight: 600;
  background-color: #ffffff;
  margin-bottom: 0.5em;
}
.heading .label {
  flex-grow: 1;
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.heading .action {
  position: relative;
  margin-right: 3px;
}
.heading .action .wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.dropTarget {
  border: 1px dashed rgba(58, 77, 96, 0.1);
  background-color: rgba(58, 77, 96, 0.05);
  padding: 20px;
  margin: 0.5em 0;
  user-select: none;
}