/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   SELECT
   =================================================================== */
.base {
  position: relative;
}
.base .arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  right: 10px;
  pointer-events: none;
}
.base .arrow .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.base .arrow .icon svg {
  width: 12px;
  height: 12px;
}
.base .arrow .icon svg {
  fill: rgba(58, 77, 96, 0.6);
}
.base.placeholder .select {
  color: rgba(58, 77, 96, 0.4);
}
.base .select {
  display: inline-block;
  height: 42px;
  width: 100%;
  outline: none;
  padding: 0 10px 0;
  border: 1px solid rgba(58, 77, 96, 0.2);
  font-family: "Catamaran", sans-serif;
  font-size: 14px;
  color: rgba(58, 77, 96, 0.8);
  border-radius: 3px;
  transition: border-color 0.3s ease;
  background-color: #ffffff;
  appearance: none;
}
.base .select:focus {
  border-color: #1761a6;
  color: #3a4d60;
  box-shadow: none;
}
.base .select[disabled] {
  background-color: transparent;
  border-color: rgba(58, 77, 96, 0.1);
  color: rgba(58, 77, 96, 0.6);
}
.base.error .select {
  border-color: rgba(208, 75, 103, 0.7);
}
.base.error .select:focus {
  box-shadow: none;
}
.base.big .select {
  height: 50px;
  font-size: 16px;
}