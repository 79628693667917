/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FILLER
   =================================================================== */
.base {
  display: block;
  height: 42px;
  line-height: 42px;
  text-align: center;
  color: rgba(58, 77, 96, 0.4);
  border: 1px solid rgba(23, 97, 166, 0.5);
  background-color: rgba(23, 97, 166, 0.1);
  width: 100%;
  overflow: hidden;
}