/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   POP OVER
   =================================================================== */
.base {
  z-index: 100;
}
.base.layerNav {
  z-index: 900;
}

.pop {
  box-shadow: 0 5px 25px 1px rgba(58, 77, 96, 0.3);
  background-color: #ffffff;
  border-radius: 8px;
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 260px;
}
.pop.sizeLarge {
  width: 300px;
}
.pop.sizeAuto {
  width: auto;
}
.pop.paddingNone {
  padding: 0;
}
.pop.paddingSmall {
  padding: 3px;
}
.pop.paddingRegular {
  padding: 10px;
}
.pop.paddingMedium {
  padding: 20px;
}
.pop.paddingLarge {
  padding: 30px;
}

.arrow.positionBottom {
  animation: animateBottom 0.2s ease-in-out forwards;
}
.arrow.positionBottom.trimPadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.arrow.positionTop {
  animation: animateTop 0.2s ease-in-out forwards;
}
.arrow.positionTop.trimPadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.arrow.positionRight {
  animation: animateRight 0.2s ease-in-out forwards;
}
.arrow.positionLeft {
  animation: animateLeft 0.2s ease-in-out forwards;
}
.arrow > div {
  border-top-color: #ffffff !important;
  border-bottom-color: #ffffff !important;
}
.arrow.colorPrimary > div {
  border-top-color: #1761a6 !important;
  border-bottom-color: #1761a6 !important;
}

@keyframes animateBottom {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(10px);
  }
}
@keyframes animateTop {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(-10px);
  }
}
@keyframes animateRight {
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(10px);
  }
}
@keyframes animateLeft {
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-10px);
  }
}