/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CODE
   =================================================================== */
.base {
  background-color: rgba(58, 77, 96, 0.1);
  display: inline-block;
  padding: 0 5px;
  margin: 0;
  font-size: 95%;
  line-height: 1.5;
  border-radius: 3px;
}