/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: relative;
  overflow: hidden;
  user-select: none;
  touch-action: pan-y;
}
.base :global .slick-list {
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
}
.base :global .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.base :global .slick-track:before {
  display: table;
  content: "";
}
.base :global .slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
}
.base .item {
  padding: 0 0.5em;
  vertical-align: top;
  position: relative;
}
.base .item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  cursor: pointer;
}
.base .item .content {
  position: relative;
  z-index: 1;
}