/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FRONT HERO
   =================================================================== */
.base {
  position: relative;
  display: flex;
  height: 100vh;
}
.base .content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-5vh);
}
@media (max-width: 599px) {
  .base .content {
    transform: none;
  }
}
.base .content .wrapper {
  position: relative;
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 55px;
}
@media (max-width: 599px) {
  .base .content .wrapper {
    row-gap: 50px;
  }
}
.base .content .wrapper .shield {
  display: inline-block;
  height: 130px;
}
@media (max-width: 599px) {
  .base .content .wrapper .shield {
    height: 100px;
  }
}
.base .content .wrapper .message {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.base .content .wrapper .message .mainline {
  font-size: 65px;
  line-height: 65px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: -1px;
  font-family: "Montserrat", sans-serif;
}
.base .content .wrapper .message .tagline {
  display: inline;
  font-size: 36px;
  line-height: 36px;
  opacity: 0.8;
  font-weight: 300;
}
.base .content .wrapper .message .tagline u {
  text-decoration-line: underline;
  text-decoration-color: #d04b67;
  text-underline-offset: 5px;
}
@media (max-width: 599px) {
  .base .content .wrapper .message {
    row-gap: 10px;
  }
  .base .content .wrapper .message .mainline {
    font-size: 42px;
    line-height: 42px;
  }
  .base .content .wrapper .message .tagline {
    font-size: 26px;
    line-height: 26px;
  }
}
.base .content .wrapper .action {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.base .arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4rem;
  opacity: 1;
  transition: opacity 0.2s linear;
  text-align: center;
}
.base .arrow svg {
  animation: jumpInfinite 3s infinite;
  width: 1.8rem;
  fill: #1761a6;
}
@media (max-width: 599px) {
  .base .arrow {
    bottom: 1rem;
  }
  .base .arrow svg {
    width: 1.5rem;
  }
}
@keyframes jumpInfinite {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2rem);
  }
  100% {
    transform: translateY(0);
  }
}
.base.continued .arrow {
  opacity: 0;
}

.carousel {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100vw;
}
.carousel .words {
  position: absolute;
  top: 0;
  animation: move 6s infinite;
  animation-delay: 3s;
}
.carousel .spacer {
  opacity: 0;
}
@keyframes move {
  20% {
    transform: translateY(-20%);
  }
  40% {
    transform: translateY(-40%);
  }
  60% {
    transform: translateY(-60%);
  }
  80% {
    transform: translateY(-80%);
  }
}