/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   PAGE MESSAGE
   =================================================================== */
.base {
  margin: 50px 0;
}
.base .figure {
  margin-bottom: 20px;
  text-align: center;
}
.base .figure svg {
  fill: #1761a6;
}
.base .image {
  margin: 0 auto 50px;
  max-width: 350px;
}
.base .title {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.base .content {
  text-align: center;
}
@media (max-width: 599px) {
  .base {
    margin-top: 20px 0;
  }
  .base .image {
    padding: 0 10px;
    margin-bottom: 30px;
  }
  .base .title {
    font-size: 30px;
  }
}