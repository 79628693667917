/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   ADDER
   =================================================================== */
.base {
  position: relative;
  display: block;
  cursor: pointer;
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgba(58, 77, 96, 0.15);
  transition: border-color 0.3s ease;
}
.base .content {
  text-align: center;
  margin: 20px 10px;
}
.base .content .icon svg {
  fill: rgba(58, 77, 96, 0.3);
  transition: fill 0.2s ease;
}
.base .content .icon svg {
  width: 24px;
  height: 24px;
}
.base .content .label {
  margin-top: 20px;
  color: rgba(58, 77, 96, 0.4);
  transition: color 0.3s ease;
  font-size: 16px;
}
.base:hover {
  border-color: rgba(58, 77, 96, 0.6);
}
.base:hover .icon svg {
  fill: #36b189;
}
.base:hover .label {
  color: rgba(58, 77, 96, 0.6);
}