/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.list {
  position: relative;
}
.list.embedded .item {
  padding: 0 10px;
}
.list.embedded .item:last-child {
  border-bottom: 0;
}
.list.embedded .item .icon {
  padding: 10px 0;
  width: 30px;
}
.list.embedded .item .icon svg {
  width: 30px;
  height: 30px;
}
.list.embedded .item .icon .locked {
  width: 18px;
  height: 18px;
  top: 4px;
  left: -4px;
}
.list.embedded .item .icon .locked svg {
  width: 8px;
  height: 8px;
}
.list.embedded .item .details {
  position: relative;
  padding: 10px;
  margin-left: 0;
}
.list.embedded .item .details .title {
  font-size: 14px;
  line-height: 1.4;
}
.list.embedded .item .details .description {
  font-size: 12px;
}
.list.embedded .item.indent {
  padding-left: 20px;
}
.list.bordered {
  border: 1px solid rgba(58, 77, 96, 0.1);
}
.list .loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.item {
  display: flex;
  cursor: default;
  border-bottom: 1px solid rgba(58, 77, 96, 0.05);
  padding: 0 20px;
  user-select: none;
  align-items: center;
}
.item .icon {
  position: relative;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}
.item .icon svg {
  width: 40px;
  height: 40px;
}
.item .icon.returnIcon svg {
  width: 30px;
  height: 30px;
}
.item .icon.returnIcon svg {
  fill: rgba(58, 77, 96, 0.3);
}
.item .icon .locked {
  position: absolute;
  left: -6px;
  top: 12px;
  width: 22px;
  height: 22px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  background-color: #1761a6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item .icon .locked svg {
  fill: #ffffff;
}
.item .icon .locked svg {
  width: 10px;
  height: 10px;
}
.item .details {
  display: block;
  flex-grow: 1;
  padding: 20px;
  overflow: hidden;
  width: 20px;
}
.item .details .title {
  font-size: 16px;
  color: rgba(58, 77, 96, 0.8);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item .custom {
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 10px;
}
.item .custom .customWrapper {
  flex-grow: 1;
}
.item .actions {
  position: relative;
}
.item .checker {
  position: relative;
  display: flex;
  align-items: center;
}
.item.selected {
  background-color: rgba(23, 97, 166, 0.15);
  font-weight: 600;
}
.item:last-child {
  border-bottom: none;
}
.item.clickable {
  cursor: pointer;
}
.item.clickable:hover {
  background-color: rgba(58, 77, 96, 0.03);
}
.item.clickable:hover .details .title {
  color: #3a4d60;
}
.item.indent {
  padding-left: 30px;
}