/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: relative;
  overflow: hidden;
}
.base .loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.base .table {
  padding: 0 20px 20px;
}
.base .table table {
  width: 100%;
  border-spacing: 0;
  overflow: hidden;
  border-top: 1px solid rgba(58, 77, 96, 0.15);
}
.base .table table th {
  white-space: nowrap;
}
.base .table table th,
.base .table table td {
  border-bottom: 1px solid rgba(58, 77, 96, 0.15);
  border-left: 1px solid rgba(58, 77, 96, 0.15);
  padding: 0 5px;
  text-align: left;
  line-height: 20px;
}
.base .table table th:last-child,
.base .table table td:last-child {
  border-right: 1px solid rgba(58, 77, 96, 0.15);
}
.base .table table .rowWeekend td:not(:nth-child(1)) {
  background-color: rgba(58, 77, 96, 0.05);
}
.base .table table .rowToday td:nth-child(1) {
  background-color: #1761a6;
}
.base .table table .rowToday td:not(:nth-child(1)) {
  background-color: rgba(246, 253, 154, 0.5);
}
.base .table table .cornerCell {
  border-bottom: 1px solid rgba(58, 77, 96, 0.15);
  font-size: 10px;
  text-align: left;
  color: rgba(58, 77, 96, 0.4);
  padding-left: 4px;
}
.base .table table .hourCell {
  position: relative;
  height: 20px;
  border-left: none;
  padding: 0;
  min-width: 10px;
}
.base .table table .hourCell .label {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  font-size: 10px;
}
.base .table table .dateCell {
  padding: 0 5px;
  text-align: center;
  width: 25px;
  font-size: 12px;
  font-weight: 600;
  background-color: #3a4d60;
  color: #ffffff;
}
.base .table table .valueCell {
  position: relative;
  padding: 0;
  border-bottom: 1px solid rgba(58, 77, 96, 0.15);
  border-left: none;
}
.base .table table .valueCell .dayLabel {
  position: absolute;
  top: 1px;
  left: 8px;
  font-size: 12px;
  color: rgba(58, 77, 96, 0.2);
  font-weight: 600;
  z-index: 1;
  text-transform: uppercase;
}
.base .table table .valueCell.clickHour {
  cursor: pointer;
}
.base .table table .valueCell.clickHour:hover {
  box-shadow: inset 0 0 0 2px rgba(23, 97, 166, 0.5);
}
.base .table table .remarksCell {
  max-width: 0;
  width: 35%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.base .table table .midLine,
.base .table table .endLine {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
}
.base .table table .endLine {
  background-color: rgba(58, 77, 96, 0.1);
}
.base .table table .midLine {
  left: 50%;
  background-color: rgba(58, 77, 96, 0.03);
}
@media (max-width: 599px) {
  .base .table {
    padding: 0;
  }
}
.base.extended .table {
  padding: 0;
}
.base.printable .table {
  line-height: 18px;
}
.base.printable .table table th,
.base.printable .table table td {
  line-height: 18px;
}
.base.printable .table .dayLabel {
  display: none !important;
}