/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   INPUT WORD
   =================================================================== */
.base .append {
  display: flex;
  height: 24px;
}
.base .append .lang {
  display: inline-block;
  margin-left: 5px;
  line-height: 27px;
  white-space: nowrap;
}