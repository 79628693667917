/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CARD
   =================================================================== */
.base {
  display: inline-block;
  height: 42px;
}
.base .image {
  vertical-align: top;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-height: 100%;
}
.base.silent .image {
  opacity: 0.4;
  filter: grayscale(100%);
}