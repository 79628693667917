/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.scoreBadge .stars {
  display: inline-flex;
  border-radius: 100px;
  width: auto;
  column-gap: 0.8em;
}
.scoreBadge .stars .star {
  position: relative;
}
.scoreBadge .stars .star svg {
  width: 3em;
  height: 3em;
}
.scoreBadge .stars .star .colored {
  position: absolute;
  opacity: 0;
  z-index: 2;
}
.scoreBadge .stars .star .colored svg {
  fill: transparent;
}
.scoreBadge .stars .star .outline svg {
  fill: rgba(58, 77, 96, 0.2);
}
.scoreBadge .stars.level1 .star:nth-child(1) .colored {
  animation: starAnimation 0.4s cubic-bezier(0.75, 0.105, 0.36, 1.28) 0.2s forwards;
  opacity: 0;
  perspective: 20em;
}
.scoreBadge .stars.level1 .star:nth-child(1) .colored svg {
  fill: #ffd91d;
}
.scoreBadge .stars.level2 .star:nth-child(1) .colored {
  animation: starAnimation 0.4s cubic-bezier(0.75, 0.105, 0.36, 1.28) 0.2s forwards;
  opacity: 0;
  perspective: 20em;
}
.scoreBadge .stars.level2 .star:nth-child(1) .colored svg {
  fill: #ffd91d;
}
.scoreBadge .stars.level2 .star:nth-child(2) .colored {
  animation: starAnimation 0.4s cubic-bezier(0.75, 0.105, 0.36, 1.28) 0.4s forwards;
  opacity: 0;
  perspective: 20em;
}
.scoreBadge .stars.level2 .star:nth-child(2) .colored svg {
  fill: #ffd91d;
}
.scoreBadge .stars.level3 .star:nth-child(1) .colored {
  animation: starAnimation 0.4s cubic-bezier(0.75, 0.105, 0.36, 1.28) 0.2s forwards;
  opacity: 0;
  perspective: 20em;
}
.scoreBadge .stars.level3 .star:nth-child(1) .colored svg {
  fill: #ffd91d;
}
.scoreBadge .stars.level3 .star:nth-child(2) .colored {
  animation: starAnimation 0.4s cubic-bezier(0.75, 0.105, 0.36, 1.28) 0.4s forwards;
  opacity: 0;
  perspective: 20em;
}
.scoreBadge .stars.level3 .star:nth-child(2) .colored svg {
  fill: #ffd91d;
}
.scoreBadge .stars.level3 .star:nth-child(3) .colored {
  animation: starAnimation 0.4s cubic-bezier(0.75, 0.105, 0.36, 1.28) 0.6s forwards;
  opacity: 0;
  perspective: 20em;
}
.scoreBadge .stars.level3 .star:nth-child(3) .colored svg {
  fill: #ffd91d;
}
.scoreBadge.sizeSmall .stars {
  background-color: rgba(58, 77, 96, 0.1);
  padding: 0.2em 0.5em;
  column-gap: 0.35em;
}
.scoreBadge.sizeSmall .stars .star svg {
  width: 1.2em;
  height: 1.2em;
}

@keyframes starAnimation {
  0% {
    transform: scale(2) rotateY(90deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.04);
    fill: #ffd91d !important;
    opacity: 1;
  }
}