/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.base .text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1em;
}
.base .text .title {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 2.8em;
  line-height: 1.1;
  padding: 0 1rem;
  width: 100%;
  text-wrap: balance;
  hyphens: auto;
}
.base .text .title span {
  display: inline-block;
  color: #304050;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  padding: 0 0.2em;
}
.base .text .description {
  color: #ffffff;
  text-shadow: 0 0 8px rgb(0, 0, 0);
  font-size: 1.2em;
  padding: 0 2em;
  text-align: center;
  width: 100%;
}
.base .text .questionCount {
  background-color: #3596d7;
  color: #ffffff;
  padding: 0.3em 1em;
  border-radius: 0.5em;
  font-weight: 600;
}
.base .branding {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 2;
  flex-direction: column;
  text-align: center;
  padding: 2em 0 2em;
}
.base .branding .shield {
  width: 2.8em;
  margin: 0 auto -0.3em;
}