/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FORM BUTTONS
   =================================================================== */
.base {
  margin-top: 10px;
}