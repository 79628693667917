/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  display: flex;
  width: 100%;
  align-items: center;
}
.base .divider {
  height: 1px;
  background-color: #1761a6;
  flex-grow: 1;
}
.base .text {
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
}