/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base .label {
  color: rgba(58, 77, 96, 0.6);
}
.base .pad {
  border: 1px solid rgba(58, 77, 96, 0.15);
  border-bottom-color: rgba(58, 77, 96, 0.4);
}
.base .pad canvas {
  vertical-align: top;
}
.base .controls {
  text-align: right;
  margin-top: 4px;
}