/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.step {
  position: absolute;
  top: 2px;
  bottom: 2px;
  background-color: #3a4d60;
  left: 0;
  right: -1px;
}
.step .clickable {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 4;
}
.step .label {
  position: absolute;
  white-space: nowrap;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 700;
  left: 7px;
  top: 1px;
  bottom: 0px;
  user-select: none;
  pointer-events: none;
  display: flex;
  align-items: center;
  line-height: 1;
  z-index: 3;
}
.step .label .remarks {
  display: inline-block;
  padding: 0 0 1px 4px;
}
.step .label .remarks svg {
  fill: #ffffff;
}
.step .label .remarks svg {
  width: 10px;
  height: 10px;
}
.step.start {
  left: auto;
  right: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.step.end {
  left: 0;
  right: auto;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.step.new:before {
  content: " ";
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0;
  right: 0;
  border-top: 2px solid red;
  border-bottom: 2px solid red;
  animation: blinking 1s ease-in-out none 20 !important;
}
.step.new.start:before {
  left: -2px;
  border-left: 2px solid red;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.step.new.end:before {
  right: -2px;
  border-right: 2px solid red;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
@keyframes blinking {
  0% {
    border-color: red;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: red;
  }
}