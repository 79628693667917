/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   PHONE INPUT
   =================================================================== */
.base {
  position: relative;
}
.base .prepend {
  display: flex;
  height: 24px;
}
.base .prepend .dialCode {
  display: inline-block;
  margin-left: 10px;
  line-height: 24px;
  white-space: nowrap;
}