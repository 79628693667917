/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   PROGRESS
   =================================================================== */
.progressBar {
  display: block;
  margin: 0;
}
.progressBar .bar .info {
  display: flex;
  column-gap: 10px;
}
.progressBar .bar .info .label {
  display: inline-block;
  color: rgba(58, 77, 96, 0.8);
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  white-space: nowrap;
}
.progressBar .bar .info .percent {
  vertical-align: top;
  float: right;
  font-weight: 700;
  flex-shrink: 0;
}
.progressBar .bar .trail {
  position: relative;
  overflow: hidden;
  height: 16px;
  background-color: rgba(58, 77, 96, 0.15);
  border-radius: 16px;
}
.progressBar .bar .trail .indicator {
  height: 100%;
  border-radius: 100px;
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%);
  background-color: #1761a6;
  background-size: 20px 20px;
  transition: width 0.2s ease;
  overflow: hidden;
}
.progressBar.spacing {
  margin: 10px 0;
}
.progressBar.animation .bar .trail .indicator {
  animation: linear-progress-bar-stripes 250ms linear infinite reverse;
}
.progressBar.sizeSmall .bar .trail {
  height: 10px;
}
.progressBar.primary .bar .trail .indicator {
  background-color: #1761a6;
}
.progressBar.success .bar .trail .indicator {
  background-color: #36b189;
}
.progressBar.warning .bar .trail .indicator {
  background-color: #ffb822;
}
.progressBar.error .bar .trail .indicator {
  background-color: #d04b67;
}
.progressBar.gradual_20 .bar .trail .indicator {
  background-color: #d04b67;
}
.progressBar.gradual_40 .bar .trail .indicator {
  background-color: #f9ab2b;
}
.progressBar.gradual_60 .bar .trail .indicator {
  background-color: #cab73e;
}
.progressBar.gradual_80 .bar .trail .indicator {
  background-color: #80b464;
}
.progressBar.gradual_100 .bar .trail .indicator {
  background-color: #36b189;
}

.progressCircle {
  position: relative;
}
.progressCircle > svg {
  display: block;
}
.progressCircle .label {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.progressCircle :global .rc-progress-circle-trail {
  stroke: rgba(58, 77, 96, 0.1) !important;
}
.progressCircle :global .rc-progress-circle-path {
  stroke: #1761a6 !important;
}
.progressCircle.success :global .rc-progress-circle-path {
  stroke: #36b189 !important;
}
.progressCircle.error :global .rc-progress-circle-path {
  stroke: #d04b67 !important;
}
.progressCircle.contrast :global .rc-progress-circle-path {
  stroke: #ffffff !important;
}
.progressCircle.rankLow :global .rc-progress-circle-path {
  stroke: #47c680 !important;
}
.progressCircle.rankMid :global .rc-progress-circle-path {
  stroke: #ff9100 !important;
}
.progressCircle.rankHigh :global .rc-progress-circle-path {
  stroke: #e02447 !important;
}
.progressCircle.gradual_20 :global .rc-progress-circle-path {
  stroke: #d04b67 !important;
}
.progressCircle.gradual_40 :global .rc-progress-circle-path {
  stroke: #f9ab2b !important;
}
.progressCircle.gradual_60 :global .rc-progress-circle-path {
  stroke: #cab73e !important;
}
.progressCircle.gradual_80 :global .rc-progress-circle-path {
  stroke: #80b464 !important;
}
.progressCircle.gradual_100 :global .rc-progress-circle-path {
  stroke: #36b189 !important;
}

@keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 20px 0;
  }
}