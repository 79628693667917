/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CENTER BOX CONTENT
   =================================================================== */
.base {
  padding: 30px 0;
}
@media (max-width: 599px) {
  .base {
    padding: 30px 0;
  }
}