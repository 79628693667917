/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FORM ITEM
   =================================================================== */
.base {
  margin-bottom: 15px;
}
.base .label {
  display: block;
}
.base .label label {
  display: flex;
  font-size: 14px;
  height: 42px;
  padding-right: 2px;
  align-items: center;
  justify-content: flex-end;
}
.base .label label .colon {
  padding: 0 5px 0 2px;
}
.base:last-child {
  margin-bottom: 0;
}
.base.spacingNone {
  margin-bottom: 0;
}
.base.spacingSmall {
  margin-bottom: 5px;
}
.base.spacingMedium {
  margin-bottom: 20px;
}
.base.hasText .field {
  font-size: 16px;
  padding-top: 6px;
}
.base.isCompact .label,
.base.isCompact .field {
  width: 100%;
}
.base.isCompact .label label {
  height: auto;
  justify-content: flex-start;
  padding-bottom: 3px;
}
@media (max-width: 1199px) {
  .base .label,
.base .field {
    width: 100%;
  }
  .base .label label {
    height: auto;
    justify-content: flex-start;
    padding-bottom: 3px;
  }
}