/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.content {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
}
.content .inner {
  position: relative;
  padding: 20px;
  width: 100%;
}
.content.noShrink {
  flex-shrink: 0;
}
.content.fill {
  height: 100%;
}
.content.paddingNone .inner {
  padding: 0;
}
.content.paddingMedium .inner {
  padding: 30px 20px;
}
.content.borderTop {
  border-top: 1px solid rgba(58, 77, 96, 0.15);
}
.content.borderBottom {
  border-bottom: 1px solid rgba(58, 77, 96, 0.15);
}