/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   COURSE COMPLETE BADGE
   =================================================================== */
.base {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
}
.base .wrapper {
  display: flex;
  flex-direction: column;
  padding: 1em 1em 6em;
  row-gap: 2em;
}
.base .symbol {
  margin: 0 auto;
  max-width: 6em;
}
.base .symbol .icon svg {
  width: 100px;
  height: 100px;
}
.base .score {
  display: block;
  text-align: center;
}
.base .score .points {
  margin-top: 1em;
}
.base .content {
  margin: 1em 4em;
}

.message {
  margin: 0 2em;
  text-align: center;
}
.message .title {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 30px;
}
.message .description {
  margin-top: 0.5em;
}

.branding {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  flex-direction: column;
  text-align: center;
  padding: 2em 0 2em;
}
.branding .shield {
  width: 2.4em;
  margin: 0 auto -0.3em;
}