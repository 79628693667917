/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   BUTTON GROUP
   =================================================================== */
.base {
  display: flex;
}
.base .item {
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  vertical-align: top;
  transition: border-color 0 linear;
}
.base .item:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.base .item:not(:last-child) {
  border-right-color: transparent;
}
.base .item:not(:first-child) {
  margin-left: -1px;
}
.base .item:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.base .item:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.base .item:hover:not(:active) {
  z-index: 1;
  border-right-color: inherit;
}