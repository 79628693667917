/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CENTER BOX
   =================================================================== */
.base {
  width: 400px;
  margin: 0 auto;
}
.base .header {
  width: 100%;
  text-align: center;
}
.base .header .logoFrame {
  width: 80px;
  margin: 0 auto;
}
.base .box {
  min-height: 200px;
  padding: 0 30px;
  text-align: center;
}
@media (max-width: calc(400px + 30px * 2)) {
  .base {
    width: 100%;
  }
}