/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   PAGE BUTTONS
   =================================================================== */
.base {
  display: flex;
  margin: 0 -5px 30px -5px;
}
.base .button {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.08);
  height: 50px;
  cursor: pointer;
  user-select: none;
  margin: 0 5px;
  padding: 0 5px;
  transition: box-shadow 0.2s linear, background-color 0.2s linear, border 0.2s ease-in-out;
  border: 3px solid transparent;
  padding: 0 20px;
  border-radius: 5px;
}
.base .button .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-right: 10px;
}
.base .button .icon svg {
  width: 19px;
  height: 19px;
}
.base .button .icon svg {
  fill: #1761a6;
}
.base .button .label {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  padding: 2px 0 0 0;
  color: rgba(58, 77, 96, 0.8);
  transition: color 0.2s linear;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.base .button .count {
  position: absolute;
  top: 0;
  transform: translateY(-60%);
  right: 5px;
}
.base .button .count .number {
  position: relative;
  text-align: center;
  font-size: 13px;
  height: 22px;
  min-width: 22px;
  line-height: 22px;
  padding: 0 5px;
  white-space: nowrap;
  color: #ffffff;
  background-color: #3a4d60;
  border-radius: 22px;
}
.base .button:hover {
  background-color: #ffffff;
  box-shadow: 0 1px 10px 1px rgba(23, 97, 166, 0.2);
  border-color: #1761a6;
}
.base .button:hover .label {
  color: #3a4d60;
}
.base .button.active {
  cursor: default;
  background-color: #1761a6;
}
.base .button.active .icon svg {
  fill: #ffffff;
  transition: fill 0.2s ease;
}
.base .button.active .label {
  color: #ffffff;
}
.base .button.active .count .number {
  background-color: #ffffff;
  color: #1761a6;
}
.base .button.active:hover {
  box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.08);
}
@media (max-width: 599px) {
  .base {
    margin-bottom: 15px;
  }
  .base .button {
    display: flex;
    justify-content: center;
  }
  .base .button .icon {
    padding-right: 0;
  }
  .base .button .label {
    display: none;
  }
}
.base.embedded {
  margin: 0;
}
.base.embedded .button {
  margin: 0;
  box-shadow: none;
  border: none;
  padding-top: 1px;
  border-bottom: 1px solid transparent;
  border-radius: 0;
}
.base.embedded .button .icon svg {
  fill: #1761a6;
}
.base.embedded .button:hover {
  box-shadow: none;
}
.base.embedded .button.active {
  background-color: #ffffff;
  border-bottom-color: transparent;
}
.base.embedded .button.active .icon svg {
  fill: rgba(58, 77, 96, 0.3);
}
.base.embedded .button.active .label {
  color: rgba(58, 77, 96, 0.4);
}
@media (max-width: 899px) {
  .base.embedded {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    margin: 0;
    box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.08);
    padding: 0;
  }
  .base.embedded .button {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 0;
  }
  .base.embedded .button .icon {
    padding-right: 0;
  }
  .base.embedded .button .label {
    display: none;
  }
  .base.embedded .button.active {
    background: #f4f7fa;
  }
}