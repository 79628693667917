/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   TASK CARD
   =================================================================== */
.base {
  position: relative;
  height: 200px;
  user-select: none;
  text-align: center;
  margin-bottom: 40px;
}
.base .number {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #3a4d60;
  color: #ffffff;
  border-radius: 2px;
  font-weight: 600;
}
.base .meter {
  position: absolute;
  left: 30px;
  top: 2px;
  right: 0;
  height: 30px;
  text-align: left;
}
.base .meter .wrapper {
  padding-left: 10px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.base .text {
  position: absolute;
  top: 30px;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 16px;
  overflow: hidden;
}
.base .text .wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 10px;
}
.base .arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -32px;
}
.base .arrow svg {
  width: 24px;
  height: 24px;
}
.base .arrow svg {
  fill: rgba(58, 77, 96, 0.8);
}
.base:hover:not(.preview) {
  cursor: pointer;
  border-color: rgba(58, 77, 96, 0.4);
}