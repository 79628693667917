/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.buttons {
  padding: 20px;
  border-top: 1px solid rgba(58, 77, 96, 0.15);
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  gap: 5px 10px;
}
.buttons .mainButton {
  vertical-align: top;
  display: inline-block;
}
.buttons .extraButton {
  vertical-align: top;
  display: inline-block;
}