/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   PARAGRAPH
   =================================================================== */
.base {
  margin: 0 0 20px 0;
}
.base.lead {
  font-size: 18px;
}
.base.centerAlign {
  text-align: center;
}
.base.rightAlign {
  text-align: right;
}
.base.noMargin {
  margin-bottom: 0;
}