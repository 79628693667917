/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   BUTTON ROW
   =================================================================== */
.base {
  white-space: nowrap;
}
.base > * {
  vertical-align: top;
  margin-right: 5px;
}
.base > *:last-child {
  margin-right: 0;
}