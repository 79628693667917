/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: relative;
  background-color: rgba(23, 97, 166, 0.07);
  border-radius: 3px;
  overflow: hidden;
  z-index: 100;
}
.base .activity {
  position: absolute;
  top: -50%;
  left: -200%;
  height: 200%;
  min-width: 50px;
  width: 40%;
  transform: rotate(20deg);
  mix-blend-mode: overlay;
  background-image: linear-gradient(90deg, rgba(58, 77, 96, 0), rgba(58, 77, 96, 0.05), rgba(58, 77, 96, 0.1), rgba(58, 77, 96, 0.05), rgba(58, 77, 96, 0));
  animation: loading 1.6s ease-in-out infinite;
  z-index: 45;
}
@keyframes loading {
  0% {
    left: -200%;
  }
  100% {
    left: 120%;
  }
}