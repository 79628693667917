/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.base.editable .text {
  cursor: pointer;
}
.base.editable .text:hover {
  text-decoration: underline;
}

.content {
  position: absolute;
  bottom: 0;
  left: 1em;
  right: 1em;
  z-index: 2;
  padding: 1em;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 0.6em 0.6em 0 0;
  transition: transform 0.3s ease-in-out;
}
.content .wrapper {
  width: 100%;
}
.content.hidden {
  transform: translateY(100%);
}
.content.fill {
  top: 0;
  left: 0;
  right: 0;
  padding: 2em;
  border-radius: 0;
  display: flex;
  align-items: center;
  background: none;
}
.content.fill .handle {
  display: none;
}
.content.fill .text {
  font-size: 1.6em;
}

.handle {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -100%);
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 0.6em 1em 0.4em;
  border-radius: 0.5em 0.5em 0 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.handle svg {
  fill: #ffffff;
}

.text {
  font-size: 1.2em;
  color: #ffffff;
  font-weight: 700;
}

.options {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  row-gap: 0.6em;
}
@keyframes slideIn {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 3.8em;
    opacity: 1;
  }
}
.options .option {
  width: 100%;
  background: #ffffff;
  border: none;
  height: 0;
  border-radius: 10em;
  cursor: pointer;
  display: flex;
  padding: 0;
  animation: 0.4s slideIn forwards;
  overflow: hidden;
  opacity: 0;
}
.options .option:hover:enabled {
  background-color: #f2f2f2;
}
.options .option:hover:enabled .symbol .dot {
  transform: scale(1.1);
}
.options .option:active:enabled {
  background-color: #e6e6e6;
}
.options .option .label {
  font-size: 1.2em;
  font-family: "Catamaran", sans-serif;
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 1.2;
  padding: 0 2em 0 0;
}
.options .option .symbol {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1em;
}
.options .option .symbol .dot {
  display: block;
  height: 1.3em;
  width: 1.3em;
  border-radius: 5em;
}
.options .option:nth-child(1) {
  animation-delay: 0.4s;
}
.options .option:nth-child(1) .symbol .dot {
  background-color: #2196f3;
}
.options .option:nth-child(2) {
  animation-delay: 0.8s;
}
.options .option:nth-child(2) .symbol .dot {
  background-color: #4caf50;
}
.options .option:nth-child(3) {
  animation-delay: 1.2s;
}
.options .option:nth-child(3) .symbol .dot {
  background-color: #ff5722;
}
.options .option:nth-child(4) {
  animation-delay: 1.6s;
}
.options .option:nth-child(4) .symbol .dot {
  background-color: #ffc107;
}
.options.answered:not(.editable) .option {
  animation: none;
  opacity: 1;
  height: 3.8em;
  cursor: default;
  pointer-events: none;
}
.options.answered:not(.editable) .option.selected {
  outline: 2px solid #ffffff;
}
.options.answered:not(.editable) .option.correct {
  background-color: #36b189;
  color: #ffffff;
}
.options.answered:not(.editable) .option.correct .symbol svg {
  fill: #ffffff;
}
.options.answered:not(.editable) .option.correct .symbol .dot {
  background-color: #ffffff;
}
.options.answered:not(.editable) .option.wrong {
  background-color: #d04b67;
  color: #ffffff;
}
.options.answered:not(.editable) .option.wrong .symbol svg {
  fill: #ffffff;
}
.options.answered:not(.editable) .option.wrong .symbol .dot {
  background-color: #ffffff;
}
.options.editable .option {
  animation: none;
  opacity: 1;
  height: 3.8em;
}
.options.editable .option.correct .symbol .dot {
  background-color: #36b189;
}
.options.editable .option.wrong .symbol .dot {
  background-color: #d04b67;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.background.variant1 {
  background: linear-gradient(0deg, #1e3952 0%, #30a8a8 100%);
}
.background.variant2 {
  background: linear-gradient(0deg, #0e0749 0%, #438baa 100%);
}
.background.variant3 {
  background: linear-gradient(0deg, #11292f 0%, #3fa296 100%);
}
.background.variant4 {
  background: linear-gradient(0deg, #0e293e 0%, #0073d1 100%);
}