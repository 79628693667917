/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.h1 {
  font-size: 38px;
  font-weight: 300;
  margin: 0 0 20px 0;
}

.marginNone {
  margin: 0;
}