/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FORM ERROR
   =================================================================== */
.base {
  display: block;
  padding: 10px;
  border-radius: 3px;
  border: 1px dashed #d04b67;
  color: #d04b67;
  font-weight: 600;
  text-align: center;
}