/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   ME MENU
   =================================================================== */
/* ME BUTTON
------------------------------------------- */
.meButton {
  position: relative;
  display: flex !important;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
}
.meButton .name {
  position: absolute;
  padding: 0 10px;
  font-weight: 600;
  font-size: 16px;
  transform: translateX(-100%);
}
.meButton:hover .name {
  color: #1761a6;
}
.meButton.placeholder .picture {
  background-color: #1761a6;
}
.meButton.placeholder .picture svg {
  fill: #ffffff !important;
}
.meButton.active .name {
  text-decoration: underline;
  text-decoration-color: #1761a6;
  text-underline-offset: 6px;
  text-decoration-thickness: 2px;
  color: #3a4d60;
}
.meButton.active .picture {
  background-color: rgba(23, 97, 166, 0.2);
}
.meButton.active .picture svg {
  fill: #1761a6 !important;
}
.meButton.contrast .name {
  color: #ffffff;
}
.meButton.contrast .picture {
  background-color: #ffffff;
  border: 2px solid #ffffff;
}
.meButton.contrast .picture svg {
  fill: #1761a6 !important;
}
.meButton.contrast.active .picture {
  background-color: #ffffff;
}
.meButton.contrast.active .picture svg {
  fill: #1761a6 !important;
}

/* Me Menu
------------------------------------------- */
.meCard {
  position: relative;
  width: 300px;
}
.meCard .line {
  position: absolute;
  right: 21px;
  top: -24px;
  height: 24px;
  border-left: 1px dashed #1761a6;
}
.meCard .header {
  position: relative;
  background-color: #1761a6;
  overflow: hidden;
  user-select: none;
}
.meCard .header .content {
  display: flex;
  align-items: center;
  padding: 20px;
}
.meCard .header .content .image {
  width: 65px;
  min-width: 65px;
}
.meCard .header .content .image .picture {
  background-color: #ffffff;
}
.meCard .header .content .details {
  padding-left: 20px;
  min-width: 0;
}
.meCard .header .content .details .name {
  font-size: 16px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.meCard .header .content .details .email {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.meCard .menu {
  padding: 10px;
}