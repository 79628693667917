/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   HAZARD ADDER
   =================================================================== */
.base {
  height: 100px;
}