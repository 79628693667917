/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
  max-width: 100%;
}
.base .label {
  color: rgba(58, 77, 96, 0.6);
}
.base tbody tr td:first-child {
  padding: 10px;
  padding-left: 0;
  color: rgba(58, 77, 96, 0.6);
  border-bottom: 1px dashed rgba(58, 77, 96, 0.1);
  vertical-align: top;
}
.base tbody tr td:last-child {
  padding: 10px;
  padding-right: 0;
  border-bottom: 1px dashed rgba(58, 77, 96, 0.1);
}
.base tbody tr:last-child td {
  border-bottom: 0;
}
.base.horizontal tbody tr td {
  padding: 0 10px;
  border-right: 1px dashed rgba(58, 77, 96, 0.1);
  text-align: center;
}
.base.horizontal tbody tr td .label {
  padding-bottom: 5px;
}
.base.horizontal tbody tr td:first-child {
  padding-left: 0;
  color: #3a4d60;
}
.base.horizontal tbody tr td:last-child {
  border-right: 0;
  padding-right: 0;
}
.base.horizontal.compact tbody tr td {
  white-space: nowrap;
}
.base.horizontal.compact tbody tr td .label {
  padding-bottom: 0;
  padding-right: 5px;
}
.base.horizontal.compact tbody tr td .value {
  color: #3a4d60;
}
.base.horizontal.compact tbody tr td .label,
.base.horizontal.compact tbody tr td .value {
  display: inline-block;
}
.base.wrap {
  width: auto;
}