/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   TEXTAREA
   =================================================================== */
.base {
  display: inline-block;
  width: 100%;
  outline: none;
  padding: 8px 10px;
  border: 1px solid rgba(58, 77, 96, 0.2);
  font-family: "Catamaran", sans-serif;
  font-size: 14px;
  color: #3a4d60;
  border-radius: 3px;
  transition: border-color 0.3s ease;
  appearance: none;
  resize: none;
  vertical-align: top;
}
.base:focus {
  border-color: #1761a6;
  box-shadow: none;
}
.base.error {
  border-color: rgba(208, 75, 103, 0.7);
}
.base.error:focus {
  box-shadow: none;
}