/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   INPUT
   =================================================================== */
.base {
  position: relative;
  /* INPUT
  --------------------------------------- */
  /* ICON
  --------------------------------------- */
  /* PREPEND
  --------------------------------------- */
  /* APPEND
  --------------------------------------- */
  /* EMPHESIZE
  --------------------------------------- */
}
.base .input {
  display: inline-block;
  height: 42px;
  width: 100%;
  outline: none;
  padding: 0 10px 0;
  border: 1px solid rgba(58, 77, 96, 0.2);
  font-family: "Catamaran", sans-serif;
  font-size: 14px;
  color: #3a4d60;
  border-radius: 3px;
  transition: border-color 0.3s ease;
  background-color: #ffffff;
  appearance: none;
}
.base .input:focus {
  border-color: #1761a6;
  box-shadow: none;
}
.base .input[disabled] {
  background-color: rgba(58, 77, 96, 0.03);
  border-color: rgba(58, 77, 96, 0.1);
  color: rgba(58, 77, 96, 0.6);
}
.base.inputIcon .input {
  padding-left: 50px;
}
.base.inputIcon .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.base.inputIcon .icon svg {
  width: 16px;
  height: 16px;
}
.base.inputIcon .icon svg {
  fill: #1d7bd3;
}
.base.inputIcon .icon:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 5px;
  bottom: 5px;
  width: 1px;
  background-color: rgba(58, 77, 96, 0);
  background-image: -webkit-linear-gradient(bottom, rgba(58, 77, 96, 0) 0, rgba(58, 77, 96, 0.15) 30%, rgba(58, 77, 96, 0.15) 70%, rgba(58, 77, 96, 0) 100%);
  background-image: linear-gradient(to top, rgba(58, 77, 96, 0) 0, rgba(58, 77, 96, 0.15) 30%, rgba(58, 77, 96, 0.15) 70%, rgba(58, 77, 96, 0) 100%);
}
.base.inputIcon.big .input {
  padding-left: 56px;
}
.base.inputIcon.big .icon {
  padding: 0 14px;
}
.base.inputPrepend {
  display: flex;
  user-select: none;
}
.base.inputPrepend .input {
  flex-grow: 1;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.base.inputPrepend .prepend {
  display: block;
  border: 1px solid rgba(58, 77, 96, 0.2);
  border-right: none;
  border-radius: 3px 0 0 3px;
  background-color: rgba(58, 77, 96, 0.03);
  padding: 0 10px;
}
.base.inputPrepend .prepend .content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
}
.base.inputPrepend .prepend.clickable {
  cursor: pointer;
}
.base.inputPrepend .prepend.clickable:hover {
  background-color: rgba(58, 77, 96, 0.05);
}
.base.inputPrepend .prepend.disabled {
  border-color: rgba(58, 77, 96, 0.1);
}
.base.inputAppend {
  display: flex;
  user-select: none;
}
.base.inputAppend .input {
  flex-grow: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.base.inputAppend .append {
  display: block;
  border: 1px solid rgba(58, 77, 96, 0.2);
  border-left: none;
  border-radius: 0 3px 3px 0;
  background-color: rgba(58, 77, 96, 0.03);
  padding: 0 10px;
}
.base.inputAppend .append .content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
}
.base.inputAppend .append.clickable {
  cursor: pointer;
}
.base.inputAppend .append.clickable:hover {
  background-color: rgba(58, 77, 96, 0.05);
}
.base.big .input {
  height: 50px;
  font-size: 16px;
}
.base.error .input {
  border-color: rgba(208, 75, 103, 0.7);
}
.base.error .input:focus {
  box-shadow: none;
}
.base.error .icon svg {
  fill: #db7389;
}
.base.emphasize .input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(58, 77, 96, 0.6);
}
.base.emphasize .input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(58, 77, 96, 0.6);
  opacity: 1;
}
.base.emphasize .input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(58, 77, 96, 0.6);
  opacity: 1;
}
.base.emphasize .input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(58, 77, 96, 0.6);
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(58, 77, 96, 0.4);
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(58, 77, 96, 0.4);
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(58, 77, 96, 0.4);
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(58, 77, 96, 0.4);
}