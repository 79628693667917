/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  margin: 5vh 0 30vh;
}
.base .action {
  text-align: center;
  margin-top: 10vh;
}
.base ul {
  list-style: none;
  padding: 0 40px;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: 70px;
}
.base ul li {
  margin: 0 auto;
  max-width: 550px;
  padding-left: 25px;
}
.base ul li::marker {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' fill='none' viewBox='0 0 31 34'%3E%3Cpath fill='%231761A6' d='M14.628 33.798a1.77 1.77 0 0 0 1.644-.001C30.035 26.397 31.012 11.94 31 7.83a1.7 1.7 0 0 0-1.024-1.553L16.148.147a1.725 1.725 0 0 0-1.397.002L1.02 6.279A1.7 1.7 0 0 0 .003 7.813c-.059 4.09.764 18.577 14.625 25.985ZM9.794 14.095l3.939 3.942 7.374-7.38 2.43 2.43-9.804 9.812-6.368-6.373 2.429-2.431Z'/%3E%3C/svg%3E");
}
.base ul li .point {
  font-size: 36px;
  font-weight: 600;
  padding-bottom: 15px;
  transform: translateY(-5px);
}
.base ul li .description {
  font-size: 16px;
  color: rgba(58, 77, 96, 0.8);
  line-height: 1.5;
}
@media (max-width: 599px) {
  .base ul {
    row-gap: 60px;
    padding: 0 20px 0 50px;
  }
  .base ul li {
    padding-left: 20px;
  }
  .base ul li::marker {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' fill='none' viewBox='0 0 31 34'%3E%3Cpath fill='%231761A6' d='M14.628 33.798a1.77 1.77 0 0 0 1.644-.001C30.035 26.397 31.012 11.94 31 7.83a1.7 1.7 0 0 0-1.024-1.553L16.148.147a1.725 1.725 0 0 0-1.397.002L1.02 6.279A1.7 1.7 0 0 0 .003 7.813c-.059 4.09.764 18.577 14.625 25.985ZM9.794 14.095l3.939 3.942 7.374-7.38 2.43 2.43-9.804 9.812-6.368-6.373 2.429-2.431Z'/%3E%3C/svg%3E");
  }
  .base ul li .point {
    font-size: 28px;
    padding-bottom: 10px;
    transform: translateY(-6px);
  }
  .base ul li .description {
    font-size: 14px;
  }
}