/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   RADIO
   =================================================================== */
.group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin: 9px 0;
}
.group.noMargin {
  margin: 0;
}

.radio {
  cursor: pointer;
  display: inline-flex;
  min-height: 24px;
  user-select: none;
}
.radio .symbol {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid rgba(58, 77, 96, 0.2);
  border-radius: 24px;
  transition: all 0.2s ease;
  background-color: #ffffff;
  flex-shrink: 0;
}
.radio .symbol .dot {
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border-radius: 50%;
  border: 1px solid #145490;
  background-color: #1761a6;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.2);
  opacity: 0;
}
.radio .label {
  padding: 3px 0 0 7px;
  color: rgba(58, 77, 96, 0.6);
  transition: all 0.2s ease;
  flex-shrink: 0;
}
.radio:hover .symbol {
  border-color: #1761a6;
  box-shadow: none;
}
.radio:hover .label {
  color: #3a4d60;
}
.radio.selected .symbol .dot {
  opacity: 1;
}
.radio.selected .label {
  color: #3a4d60;
}
.radio.selected:hover {
  cursor: default;
}
.radio.selected:hover .symbol {
  border: 1px solid rgba(58, 77, 96, 0.2);
  box-shadow: none;
}