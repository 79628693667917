/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   TIME INTERVAL SELECT
   =================================================================== */
.base .textAlignRight {
  text-align: right;
  line-height: 42px;
}
.base .textAlignLeft {
  text-align: left;
  line-height: 42px;
}