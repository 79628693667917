/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  padding: 30px;
  background-color: #1761a6;
  border-radius: 5px;
}
.base .message {
  margin-top: 20px;
  text-align: center;
  max-width: 220px;
}
.base .message .label {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}
.base .message .description {
  color: rgba(255, 255, 255, 0.6);
  margin-top: 10px;
}