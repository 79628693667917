/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   DATE TIME PICKER
   =================================================================== */
.base {
  display: flex;
}
.base .date {
  width: 65%;
}
.base .time {
  width: 35%;
  margin-left: 5px;
}