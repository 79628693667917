/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CREDIT CARD FORM
   =================================================================== */
.card :global .StripeElement {
  border: 1px solid rgba(58, 77, 96, 0.2);
  padding: 12px 10px;
  border-radius: 3px;
}
.card :global .StripeElement--focus {
  border-color: #1761a6;
}