/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  margin: 0 1em;
  transition: opacity 0.2s ease, transform 0.2s ease;
}
.base .items {
  position: relative;
  height: 0.3em;
  display: flex;
  column-gap: 4px;
}
.base .items .item {
  flex-grow: 1;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  transition: width 0.2s ease;
  border-radius: 1em;
  transition: background 0.2s ease;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
}
.base .items .item.passed {
  background: #ffffff;
}
.base .items .item.current {
  background: #ffffff;
}
.base.hidden {
  opacity: 0;
  transform: translateY(-100%);
}