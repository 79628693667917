/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.button {
  --button-size: 38px;
  height: var(--button-size);
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: var(--button-size);
  transition: background 0.2s ease, transform 0.2s ease-in-out;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  line-height: 1;
  cursor: pointer;
}
.button .label {
  padding: 0 10px 0 20px;
  font-weight: 600;
}
.button .symbol {
  background-color: #4a99e2;
  border-radius: var(--button-size);
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  height: 100%;
}
.button .symbol .icon {
  animation: iconOff 0.1s 1 linear;
}
.button .symbol .icon svg {
  fill: #ffffff;
}
.button:hover {
  background-color: #ffffff;
}
.button.active {
  box-shadow: none;
}
.button.active .symbol {
  background-color: rgba(23, 97, 166, 0.4);
}
.button.active .symbol .icon {
  animation: iconOn 0.1s 1 linear;
}
.button.active .symbol .icon svg {
  width: 14px;
  height: 14px;
}
@keyframes iconOn {
  from {
    filter: blur(2px);
  }
  to {
    filter: blur(0);
    transform: rotate(90deg);
  }
}
@keyframes iconOff {
  from {
    filter: blur(2px);
  }
  to {
    filter: blur(0);
    transform: rotate(-90deg);
  }
}
@media (max-width: 599px) {
  .button {
    --button-size: 36px;
  }
}