/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  display: inline-block;
  height: 22px;
  width: 30px;
  background-color: #675dff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.base svg {
  height: 56%;
  vertical-align: top;
  display: inline-block;
  fill: #fff;
}