/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FEATURE LIST
   =================================================================== */
.base {
  max-width: 1200px;
  margin: 0 auto;
}
.base .item {
  position: relative;
  display: flex;
  margin: 0 100px 200px;
  column-gap: 60px;
  row-gap: 40px;
}
.base .item:last-child {
  margin-bottom: 250px;
}
.base .item .message {
  width: 50%;
  display: flex;
  align-items: center;
  text-align: right;
}
.base .item .message .title {
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
}
.base .item .message .description {
  font-size: 18px;
  color: rgba(58, 77, 96, 0.8);
}
.base .item .message .button {
  display: inline-block;
  background-color: #1761a6;
  color: #ffffff;
  font-size: 18px;
  padding: 10px 26px;
  border-radius: 40px;
  cursor: pointer;
}
.base .item .message .button:hover {
  background-color: #1a6ebc;
}
.base .item .illustration {
  width: 50%;
  max-height: 300px;
}
.base .item .illustration .image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.base .item.variant .message {
  order: 1;
  text-align: left;
}
.base .item.variant .illustration {
  order: 0;
}
@media (max-width: 899px) {
  .base .item {
    margin: 0 50px 150px;
  }
}
@media (max-width: 599px) {
  .base .item {
    flex-direction: column;
    margin: 0 30px 150px;
  }
  .base .item .message {
    width: auto;
    order: 1;
    text-align: center;
  }
  .base .item .message .title {
    font-size: 26px;
    line-height: 26px;
  }
  .base .item.variant .message {
    text-align: center;
  }
  .base .item .illustration {
    width: auto;
    padding: 0 20px;
  }
}