/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CONFIRM ACTION BUTTON
   =================================================================== */
.base .button {
  border: 1px solid rgba(208, 75, 103, 0.5);
}
.base .prompt {
  background-image: linear-gradient(90deg, #d04b67 50%, transparent 50%), linear-gradient(90deg, #d04b67 50%, transparent 50%), linear-gradient(0deg, #d04b67 50%, transparent 50%), linear-gradient(0deg, #d04b67 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 5px 1px, 5px 1px, 1px 5px, 1px 5px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 0.5s infinite linear;
  padding: 20px;
  border-radius: 3px;
}
.base .prompt .question {
  padding: 5px 20px 20px;
  text-align: center;
  font-weight: 600;
  color: #d04b67;
}
@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 5px top, right 5px bottom, left bottom 5px, right top 5px;
  }
}
.base.silent .button {
  border-color: transparent;
}