/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CENTER BOX BOTTOM
   =================================================================== */
.base {
  padding: 30px 0;
  border-top: 1px solid rgba(58, 77, 96, 0.1);
}
@media (max-width: 599px) {
  .base {
    padding: 20px 0;
  }
}