/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.html {
  font-size: 16px;
}
.html a {
  color: #1761a6;
  text-decoration: underline;
}
.html a:hover {
  cursor: pointer;
  color: #4a99e2;
}
.html p {
  margin: 0 0 30px 0;
}
.html p:first-child {
  margin-top: 0;
}
.html p:last-child {
  margin-bottom: 0;
}
.html h1 {
  margin: 0 0 30px 0;
  font-size: 30px;
}
.html h2 {
  margin: 0 0 30px 0;
  font-size: 24px;
}
.html h3 {
  margin: 0 0 30px 0;
  font-size: 20px;
}
.html h4 {
  margin: 0 0 30px 0;
  font-size: 18px;
}
.html blockquote {
  margin: 0 0 30px 0;
  font-size: 20px;
  font-style: italic;
  border-left: 3px solid #1761a6;
  padding: 5px 0 5px 10px;
}
.html blockquote p {
  margin: 0;
}
.html pre {
  max-width: 100%;
  white-space: pre-wrap;
  margin: 0 0 30px 0;
  font-size: 14px;
  line-height: 1.4;
}
.html ol {
  margin: 0 0 30px 0;
  padding-left: 25px;
}
.html ol li {
  padding-left: 5px;
}
.html ul {
  margin: 0 0 30px 0;
  list-style-type: square;
  padding-left: 30px;
}
.html img {
  vertical-align: top;
  max-width: 100%;
  min-width: 10px;
  min-height: 10px;
  border-radius: 3px;
  margin-bottom: 30px;
}
.html table {
  width: 100%;
  margin: 0 0 30px 0;
  border-spacing: 0;
  table-layout: fixed;
  word-wrap: break-word;
}
.html table p {
  margin: 0 0 5px 0;
}
.html table thead tr th {
  color: rgba(58, 77, 96, 0.8);
  text-align: left;
  font-weight: 600;
  padding: 7px 10px;
  background-color: rgba(58, 77, 96, 0.03);
  border-bottom: 1px solid rgba(58, 77, 96, 0.15) !important;
  border-right: 1px solid rgba(58, 77, 96, 0.1) !important;
  font-size: 14px;
}
.html table thead tr th:first-child {
  border-left: 1px solid rgba(58, 77, 96, 0.25) !important;
}
.html table thead tr th:last-child {
  border-right: 1px solid rgba(58, 77, 96, 0.25) !important;
}
.html table thead tr:first-child th {
  border-top: 1px solid rgba(58, 77, 96, 0.25) !important;
}
.html table thead tr:first-child th:first-child {
  border-top-left-radius: 3px !important;
}
.html table thead tr:first-child th:last-child {
  border-top-right-radius: 3px !important;
}
.html table thead + tbody tr:first-child td {
  border-top: 0 !important;
}
.html table thead + tbody tr:first-child td:first-child {
  border-top-left-radius: 0;
}
.html table thead + tbody tr:first-child td:last-child {
  border-top-right-radius: 0;
}
.html table tbody tr td,
.html table tbody tr th {
  padding: 7px 10px;
  border-bottom: 1px solid rgba(58, 77, 96, 0.1);
  border-right: 1px solid rgba(58, 77, 96, 0.1);
  font-size: 14px;
  height: 35.6px;
}
.html table tbody tr td:first-child,
.html table tbody tr th:first-child {
  border-left: 1px solid rgba(58, 77, 96, 0.25) !important;
}
.html table tbody tr td:last-child,
.html table tbody tr th:last-child {
  border-right: 1px solid rgba(58, 77, 96, 0.25) !important;
}
.html table tbody tr:first-child td,
.html table tbody tr:first-child th {
  border-top: 1px solid rgba(58, 77, 96, 0.25) !important;
}
.html table tbody tr:first-child td:first-child,
.html table tbody tr:first-child th:first-child {
  border-top-left-radius: 3px;
}
.html table tbody tr:first-child td:last-child,
.html table tbody tr:first-child th:last-child {
  border-top-right-radius: 3px;
}
.html table tbody tr:last-child td,
.html table tbody tr:last-child th {
  border-bottom: 1px solid rgba(58, 77, 96, 0.25) !important;
}
.html table tbody tr:last-child td:first-child,
.html table tbody tr:last-child th:first-child {
  border-bottom-left-radius: 3px;
}
.html table tbody tr:last-child td:last-child,
.html table tbody tr:last-child th:last-child {
  border-bottom-right-radius: 3px;
}
.html table tbody th {
  background-color: rgba(23, 97, 166, 0.07);
  text-align: left;
  font-weight: 600;
}