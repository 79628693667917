/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CUSTOM SELECT
   =================================================================== */
.option {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.option .symbol {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.option .symbol.image {
  height: 42px;
  width: 42px;
}
.option .label {
  flex-grow: 1;
  padding-top: 3px;
}
.option .description {
  color: rgba(58, 77, 96, 0.4);
  font-size: 12px;
}
.option.clickable {
  cursor: pointer;
  color: #3a4d60;
}
.option.selected .symbol.image {
  height: 30px;
  width: 30px;
}
.option.selected .description {
  display: none;
}

:global .cselect__value-container {
  overflow: visible;
  height: 100%;
}
:global .cselect__menu-portal {
  z-index: 900 !important;
}
:global .cselect__menu .cselect__option {
  color: #3a4d60;
}
:global .cselect__placeholder {
  padding-top: 3px;
  color: rgba(58, 77, 96, 0.4) !important;
}
:global .cselect__control {
  border: 1px solid rgba(58, 77, 96, 0.2) !important;
  font-family: "Catamaran", sans-serif;
  height: 42px !important;
}
:global .cselect__control:hover {
  border: 1px solid rgba(58, 77, 96, 0.2) !important;
}
:global .cselect__control.cselect__control--is-focused {
  border-color: #1761a6 !important;
  box-shadow: none;
}
:global .cselect__control.cselect__control--is-focused:hover {
  border-color: #1761a6 !important;
}