/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   ON/OFF SWITCH
   =================================================================== */
.base {
  position: relative;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  padding: 6px 30px 6px 0;
}
.base .wrapper {
  display: flex;
  align-items: center;
}
.base .switch {
  overflow: hidden;
  position: relative;
  display: inline-block;
  height: 30px;
  width: 64px;
  min-width: 64px;
  border-radius: 30px;
  background-color: rgba(23, 97, 166, 0.5);
  transition: background-color 0.3s ease;
}
.base .switch .handle {
  position: absolute;
  top: 3px;
  left: calc(50% - 15px + 3px);
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 30px;
  transition: left 0.3s ease-in-out;
  text-align: center;
  padding-top: 1px;
}
.base .switch .handle svg {
  fill: rgba(58, 77, 96, 0.25);
}
.base .switch .onIcon {
  position: absolute;
  right: -26px;
  top: 50%;
  transform: translateY(-50%);
  transition: right 0.3s ease-in-out;
}
.base .switch .onIcon svg {
  fill: #ffffff;
}
.base .switch .offIcon {
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.3s ease-in-out;
}
.base .switch .offIcon svg {
  fill: #ffffff;
}
.base .label {
  padding: 7px 0 5px 10px;
  transition: all 0.2s ease;
  flex-grow: 1;
}
.base.isOn .switch {
  background-color: #36b189;
}
.base.isOn .switch .handle {
  left: 3px;
}
.base.isOn .switch .onIcon {
  right: 14px;
}
.base.isOff .switch {
  background-color: #d04b67;
}
.base.isOff .switch .handle {
  left: calc((100% - 30px) + 3px);
}
.base.isOff .switch .onIcon {
  right: -26px;
}
.base.isOff .switch .offIcon {
  left: 14px;
}
.base.silent.isOff .switch {
  background-color: rgba(58, 77, 96, 0.25);
}
.base.noLabel {
  min-height: 30px;
  padding-right: 0;
}
.base.disabled {
  cursor: default;
}
.base.disabled .switch {
  background-color: rgba(58, 77, 96, 0.4);
}