/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base .item {
  border-bottom: 1px solid rgba(58, 77, 96, 0.1);
  cursor: pointer;
  position: relative;
}
.base .item .wrapper {
  padding: 20px;
  padding-top: 35px;
}
.base .item .info {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
}
.base .item .info .ident {
  padding: 2px 10px 2px 8px;
  background-color: rgba(58, 77, 96, 0.05);
  color: rgba(58, 77, 96, 0.4);
  font-size: 12px;
  font-weight: 600;
  border-bottom-right-radius: 4px;
}
.base .item:hover {
  background-color: rgba(23, 97, 166, 0.07);
}
.base .item:hover .ident {
  background-color: #3a4d60;
  color: #ffffff;
}
.base .item:last-child {
  border-bottom: 0;
}
.base .item.attention {
  background-color: rgba(246, 253, 154, 0.5);
}
.base .item.attention:hover {
  background-color: rgba(246, 253, 154, 0.8);
}
.base .loader {
  height: 80px;
  display: flex;
  align-items: center;
}
.base .empty {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(58, 77, 96, 0.05);
  color: rgba(58, 77, 96, 0.4);
}