/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CLICKABLE
   =================================================================== */
.base {
  display: inherit;
  text-decoration: inherit;
  color: inherit;
}