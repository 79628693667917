/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   VESSEL PRICE PLAN
   =================================================================== */
.base {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(58, 77, 96, 0.3);
  border-radius: 8px;
  height: 100%;
}
.base .header {
  position: relative;
}
.base .header .title {
  line-height: 1;
  display: inline-block;
  text-align: center;
  padding: 20px;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  color: #3a4d60;
}
.base .header .cornerBadge {
  position: absolute;
  top: 18px;
  right: 20px;
}
.base .header .pricing {
  padding: 10px 20px 20px;
  position: relative;
  height: 100px;
}
.base .header .pricing .contact {
  font-size: 30px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  font-family: "Montserrat", sans-serif;
}
.base .header .pricing .price {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  font-family: "Montserrat", sans-serif;
}
.base .header .pricing .price .amount {
  position: relative;
  display: inline-block;
  font-size: 38px;
  font-weight: 700;
}
.base .header .pricing .price .amount .decimals {
  position: absolute;
  right: -3px;
  transform: translateX(100%);
  top: 8px;
  line-height: 1.4;
  font-weight: 700;
  font-size: 14px;
}
.base .header .pricing .price .suffix {
  font-size: 12px;
  font-weight: 700;
  padding-left: 2px;
}
.base .header .pricing .price .vat {
  display: inline-block;
  font-size: 10px;
  color: rgba(58, 77, 96, 0.5);
  padding-left: 5px;
  font-family: "Catamaran", sans-serif;
}
.base .header .pricing .description {
  color: rgba(58, 77, 96, 0.6);
  margin-top: -5px;
}
.base .features {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: none;
  flex-grow: 1;
}
.base .features li {
  display: flex;
  padding: 5px 20px;
}
.base .features li .symbol svg {
  fill: rgba(23, 97, 166, 0.5);
}
.base .features li .text {
  padding-left: 5px;
  font-size: 16px;
}
.base .included {
  margin: 10px 20px;
  background-color: rgba(23, 97, 166, 0.05);
  border-radius: 8px;
}
.base .included .title {
  font-size: 16px;
  color: #3a4d60;
  padding: 10px 15px;
  transition: margin-bottom 0.3s ease-in-out;
  user-select: none;
  cursor: pointer;
}
.base .included .title .counter {
  float: right;
  font-size: 12px;
  font-weight: 700;
  background-color: rgba(23, 97, 166, 0.1);
  color: rgba(23, 97, 166, 0.7);
  border-radius: 30px;
  padding: 2px 5px;
  margin: 0 10px;
}
.base .included .title .icon {
  float: right;
}
.base .included .title .icon svg {
  fill: rgba(23, 97, 166, 0.5);
}
.base .included .title .icon svg {
  width: 10px;
  height: 10px;
}
.base .included .title:hover .icon svg {
  fill: #1761a6;
}
.base .included ul {
  margin: 0;
  padding: 0 15px 0px 30px;
  height: 0;
  overflow: hidden;
}
.base .included ul li {
  padding-bottom: 3px;
}
.base .included ul li::marker {
  color: rgba(23, 97, 166, 0.5);
}
.base .included.visible ul {
  height: auto;
  padding-bottom: 10px;
}
.base .limitations {
  padding: 20px;
  height: 70px;
}
.base .action {
  padding: 20px;
}
.base.variantSilent {
  border: 1px dashed rgba(58, 77, 96, 0.3);
}
.base.variantSilent .header,
.base.variantSilent .features,
.base.variantSilent .included {
  opacity: 0.5;
}
.base.variantHighlighted {
  border-color: #1761a6;
  outline: 4px solid rgba(23, 97, 166, 0.1);
}
.base .status {
  margin: 20px 20px 0;
}
.base .status .statusBadge {
  display: inline-block;
  color: #3a4d60;
  text-transform: uppercase;
  font-weight: 600;
  padding: 3px 10px 2px;
  border: 1px solid #1761a6;
  border-radius: 5px;
  text-align: center;
}
.base .status .statusBadge.canceled {
  border-color: #d04b67;
  color: #d04b67;
}