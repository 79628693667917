/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   LOADER HORIZONTAL
   =================================================================== */
.base {
  position: relative;
  width: 77px;
  height: 6px;
  overflow: hidden;
  margin: auto;
  border-radius: 50px;
}
.base .bar {
  position: relative;
  width: 77px;
  height: 6px;
  background-color: #ffffff;
  margin-top: 0;
  margin-left: -77px;
  animation-name: loaderAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 2;
}
.base .bkg {
  top: 0;
  left: 1px;
  right: 1px;
  position: absolute;
  height: 6px;
  background-color: #1761a6;
  border-radius: 50px;
}

@keyframes loaderAnimation {
  0% {
    margin-left: -77px;
  }
  100% {
    margin-left: 77px;
  }
}