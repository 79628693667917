/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   TOOLTIP
   =================================================================== */
.base {
  padding: 5px 15px !important;
  max-width: 300px;
  box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.08);
}

.tip {
  display: inline-block;
}