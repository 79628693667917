/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FIGURE
   =================================================================== */
.base {
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
}

.colorPrimary {
  fill: #1761a6;
}

.sizeRegular {
  width: 40px;
  height: 40px;
}

.sizeMedium {
  width: 100px;
  height: 100px;
}

.sizeLarge {
  width: 150px;
  height: 150px;
}