/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.item {
  border-bottom: 1px solid rgba(58, 77, 96, 0.05);
  user-select: none;
  min-height: 42px;
  padding: 0.5em 0;
  position: relative;
}
.item .frame {
  position: absolute;
  top: 0;
  left: -10px;
  bottom: 0;
  right: -10px;
  z-index: 1;
}
.item .inner {
  display: flex;
  position: relative;
  z-index: 2;
  column-gap: 10px;
}
.item .content {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  min-width: 0;
}
.item .content .wrapper {
  flex-grow: 1;
  max-width: 100%;
}
.item .prepend {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.item .prepend .wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.item.prependAlignTop .prepend {
  align-items: flex-start;
}
.item .append {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.item .append .wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.item.appendAlignTop .append {
  align-items: flex-start;
}
.item .handle {
  min-height: 100%;
  padding: 0 5px;
  cursor: ns-resize;
  display: flex;
  align-items: center;
}
.item .handle .icon {
  vertical-align: 2px;
}
.item .handle .icon svg {
  fill: rgba(58, 77, 96, 0.15);
}
.item .handle:hover {
  background-color: rgba(58, 77, 96, 0.05);
  border-radius: 3px;
}
.item .handle:hover .icon svg {
  fill: rgba(58, 77, 96, 0.6);
}
.item:last-child {
  border-bottom: none;
}
.item.clickable:hover {
  cursor: pointer;
}
.item.clickable:hover .frame {
  background-color: rgba(234, 240, 245, 0.3);
}
.item.clickable:active .frame {
  background-color: rgba(234, 240, 245, 0.5);
}
.item.isHighlighted:not(:hover) .frame {
  animation: attentionBlink 1s ease-in-out none infinite;
}
@keyframes attentionBlink {
  0% {
    background-color: rgba(246, 253, 154, 0.2);
  }
  50% {
    background-color: rgba(246, 253, 154, 0.6);
  }
  100% {
    background-color: rgba(246, 253, 154, 0.2);
  }
}

.dragging .frame {
  background-color: #ffffff;
  box-shadow: 0 5px 25px 1px rgba(58, 77, 96, 0.3);
}