/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   RISK TABLE
   =================================================================== */
.base .frame .table {
  border-collapse: collapse;
}
.base .frame .table tr td {
  vertical-align: top;
}
.base .frame .table tr td .wrapper {
  margin-right: 10px;
  width: 300px;
}