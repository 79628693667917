/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.toast {
  color: #3a4d60;
  font-family: "Catamaran", sans-serif;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 12px;
  background: #ffffff;
}

.content {
  display: flex;
  column-gap: 10px;
}
.content .symbol {
  flex-shrink: 0;
}
.content .symbol .icon {
  width: 18px;
  height: 18px;
}
.content .details {
  flex-grow: 1;
  vertical-align: top;
  margin-bottom: -1px;
}
.content .details .message {
  font-weight: 600;
}
.content .details .description {
  margin-top: 4px;
  color: rgba(58, 77, 96, 0.8);
}