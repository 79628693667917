/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.timeSelector {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transform: scale(1.2);
}
.timeSelector.visible {
  width: auto;
  transform: scale(1);
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  touch-action: none;
}

.clock {
  width: 288px;
  height: 288px;
  border-radius: 280px;
  border: 1px solid rgba(58, 77, 96, 0.2);
  position: relative;
}
.clock .timeValues {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}
.clock .timeValues .time {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 40px;
  background-color: transparent;
  font-family: "Catamaran", sans-serif;
  font-weight: 600;
  color: rgba(58, 77, 96, 0.4);
  pointer-events: all;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
}
.clock .timeValues .time:hover {
  background: rgba(58, 77, 96, 0.05);
}
.clock .timeValues.focused .time:not(.silent) {
  color: #3a4d60;
}
.clock .hand {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: #1761a6;
  transform-origin: 50% 100%;
  margin: -100px -2px 0;
  padding: 100px 2px 0;
  z-index: 2;
  border-radius: 0 0 2px 2px;
  pointer-events: none;
}
.clock .hand button {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: none;
  background-color: transparent;
  top: 0;
  transform: translate(-50%, -100%);
  cursor: pointer;
  outline: none;
  pointer-events: all;
  background-color: #1761a6;
  color: white;
  font-weight: 700;
  box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.08);
  font-family: "Catamaran", sans-serif;
  font-size: 1rem;
}
.clock .hand.inner {
  margin: -60px -2px 0;
  padding: 60px 2px 0;
}