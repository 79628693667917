/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   ILLUSTRATION
   =================================================================== */
.base {
  min-height: 100%;
  height: 100%;
}
.base.silent .bkg {
  fill: #aaa !important;
}
.base.outline .bkg {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 15px;
}