/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CELL
   =================================================================== */
.cell {
  padding: 7px 10px;
}
.cell .input {
  outline: none;
  resize: none;
  border: 0;
  background: transparent;
  font-size: 14px;
  font-family: "Catamaran", sans-serif;
  padding: 7px 10px;
}
.cell.alignRight {
  text-align: right;
}
.cell.alignCenter {
  text-align: center;
}
.cell.editMode {
  padding: 0;
}