/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.4;
}
@media (max-width: 450px) {
  .base {
    font-size: 14px;
  }
}
@media (max-height: 600px) {
  .base {
    font-size: 13px;
  }
}
.base .wrapper {
  position: relative;
  z-index: 3;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 450px;
  max-width: 100vw;
  min-width: 319px;
  min-height: 549px;
  display: flex;
  align-items: center;
}
.base .wrapper .cards {
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  height: auto;
  width: 100%;
  max-height: 100%;
}
.base .wrapper .cards .slider {
  overflow: hidden;
}
.base .wrapper .cards .uploading {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
}
@media (max-height: 800px) {
  .base .wrapper {
    width: auto;
    aspect-ratio: 9/16;
  }
}
@media (max-width: 450px) {
  .base .wrapper {
    aspect-ratio: auto;
    width: 100%;
  }
}
.base .background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.base .background .diffuse {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #1761a6 0%, #0e3a63 100%);
  z-index: 2;
}
.base .background img {
  filter: blur(10px);
}

.controls {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
}
.controls .leftSide {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  padding: 1em 0;
}
.controls .rightSide {
  display: block;
  position: absolute;
  top: 0;
  right: -0.5em;
  transform: translateX(100%);
  pointer-events: all;
}
.controls .user {
  display: none;
}
.controls .tools {
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  min-width: 42px;
}
.controls .badge {
  margin: 0 1em;
}
@media (max-height: 960px) {
  .controls .user {
    display: block;
    margin-bottom: 0.4em;
  }
}
@media (max-height: 800px) {
  .controls .tools {
    margin: 0.4em 0.4em 0 0;
  }
  .controls .user {
    margin-top: 0.4em;
  }
}
@media (max-width: 580px) {
  .controls {
    display: flex;
  }
  .controls .rightSide {
    position: relative;
    transform: none;
    right: 0;
  }
  .controls .tools {
    background: rgba(0, 0, 0, 0.3);
    margin: 0.4em 0.4em 0 0;
  }
  .controls .user {
    display: block;
    margin: 0.4em 0.4em 0 0;
  }
}

.error {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 84, 144, 0.8);
  backdrop-filter: blur(10px);
  z-index: 4;
  align-items: center;
  text-align: center;
  padding: 0 3em;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
@media (max-width: 319px), (max-height: 549px) {
  .error {
    display: flex;
  }
}