/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  height: 60px;
  padding: 10px 20px;
}
.base .label {
  flex-grow: 1;
  font-size: 18px;
  text-align: center;
}