/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  padding: 10px 20px 20px;
}
.base .content {
  min-height: 50px;
  max-height: 400px;
}