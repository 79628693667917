/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: absolute;
  opacity: 0;
  box-shadow: 0 5px 25px 1px rgba(58, 77, 96, 0.3);
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.2s, transform 0.2s, top 0.1s, left 0.1s;
  border-radius: 8px;
  white-space: nowrap;
  display: flex;
}
.base .wrapper {
  border-radius: 8px;
}
.base .progress {
  width: 200px;
  padding: 0 10px;
  background-color: #ffffff;
  border-radius: 8px;
}
.base.visible {
  opacity: 1;
  pointer-events: all;
  transform: translateY(8px);
}