/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  margin-top: -4px;
}
.base .dateBlock {
  padding: 13px 5px 5px;
  border-radius: 3px;
  border: 1px solid rgba(58, 77, 96, 0.1);
  height: 100%;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.base .dateBlock .label {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 4px 15px 2px;
  border-radius: 100px;
  line-height: 1;
  border: 1px solid rgba(58, 77, 96, 0.1);
}
.base .dateBlock .time {
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  border-bottom: 1px solid rgba(58, 77, 96, 0.1);
}
.base .dateBlock .date {
  line-height: 28px;
  opacity: 0.6;
}
.base .dateBlock .time:active,
.base .dateBlock .date:active {
  background-color: rgba(58, 77, 96, 0.05);
  border-radius: 3px;
}
.base .dateBlock.selected {
  border-color: rgba(23, 97, 166, 0.8);
}
.base .dateBlock.selected .label {
  background-color: #1761a6;
  border-color: #1761a6;
  color: #ffffff;
}
.base .dateBlock.selected .date {
  opacity: 0.8;
}
.base .hourBlock {
  padding: 5px;
  border-radius: 3px;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.base .hourBlock .hours {
  color: #1761a6;
  font-size: 34px;
  line-height: 34px;
  font-weight: 700;
}
.base .hourBlock .hours.invalid {
  font-size: 28px;
  line-height: 28px;
  color: #d04b67;
}
.base .hourBlock .label {
  text-transform: lowercase;
}