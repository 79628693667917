/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   PAGE HEADER
   =================================================================== */
.base {
  position: relative;
  transition: box-shadow 0.1s linear;
  background: #e0e9f0;
}
.base .bar .wrapper {
  display: flex;
  min-height: 60px;
  align-items: center;
  min-width: 0;
  column-gap: 20px;
  transition: padding 0.2s linear;
}
.base .bar .wrapper .titles {
  flex-grow: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.base .bar .wrapper .titles .icon {
  margin-right: 8px;
}
.base .bar .wrapper .titles .icon svg {
  width: 24px;
  height: 24px;
}
.base .bar .wrapper .titles .icon svg {
  fill: #1761a6;
}
.base .bar .wrapper .titles .image {
  height: 80px;
  aspect-ratio: 1/1;
  margin-right: 20px;
  transition: height 0.2s linear, margin 0.2s linear;
  box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.08);
  border: 2px solid #1761a6;
  border-radius: 5px;
  padding: 2px;
}
.base .bar .wrapper .titles .title {
  display: inline;
  font-size: 24px;
  line-height: 1;
  color: #3a4d60;
  font-weight: 700;
  padding-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: font-size 0.2s linear;
}
.base .bar .wrapper .titles .description {
  font-size: 14px;
  font-weight: 400;
  color: rgba(58, 77, 96, 0.6);
  padding-top: 3px;
}
.base .bar .wrapper .choices {
  flex-shrink: 0;
}
.base.colorError .titles .icon svg {
  fill: #d04b67 !important;
}
.base.hasImage .bar .wrapper {
  padding: 20px 0;
}
.base.pinned {
  box-shadow: 0 1px 10px 1px rgba(58, 77, 96, 0.1);
}
.base.pinned.hasImage .bar .wrapper {
  padding: 10px 0;
}
.base.pinned.hasImage .bar .wrapper .titles .image {
  height: 60px;
  margin-right: 15px;
}
@media (max-width: 599px) {
  .base .bar {
    column-gap: 10px;
  }
  .base .bar .wrapper {
    min-height: 54px;
  }
  .base .bar .wrapper .titles .icon svg {
    width: 20px;
    height: 20px;
  }
  .base .bar .wrapper .titles .image {
    height: 60px;
  }
  .base .bar .wrapper .titles .title {
    font-size: 18px;
    padding-top: 2px;
  }
  .base .bar .wrapper .titles .description {
    font-size: 12px;
  }
  .base.hasImage .bar .wrapper .titles .title {
    font-size: 20px;
  }
  .base.pinned.hasImage .bar .wrapper .titles .image {
    height: 50px;
  }
}