/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.base .wrapper {
  position: absolute;
  top: 5em;
  left: 2em;
  right: 2em;
  bottom: 2em;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
}

.arrow {
  position: absolute;
  top: 13%;
  right: 3em;
  width: 10em;
  transform: rotate(154deg);
  transform-origin: center center;
}
.arrow svg {
  fill: #ffffff;
}

.description {
  text-align: center;
  padding: 0 2em;
  font-size: 1.4em;
  color: #ffffff;
}