/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: relative;
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
}
.base .wrapper {
  position: relative;
  margin: 0 25px;
  height: 100%;
}
.base.alignLeft {
  margin: 0;
}
@media (max-width: 599px) {
  .base .wrapper {
    margin: 0 15px;
  }
}