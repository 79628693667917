.base.silent :global .ps__rail-x {
  bottom: auto;
  top: 0;
  height: 0px;
}
.base.silent :global .ps__rail-x:hover > .ps__thumb-x {
  height: 4px;
}
.base.silent :global .ps__thumb-x {
  bottom: 0;
  height: 0px;
  opacity: 0;
}
.base.silent :global .ps__thumb-x:hover {
  opacity: 0.4;
}