/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   RADIO LIST
   =================================================================== */
.base {
  position: relative;
  display: block;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 3px;
}
.base.error {
  border-color: rgba(208, 75, 103, 0.7);
}
.base .loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}
.base .loader .spinner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.base .heading {
  display: flex;
  text-align: left;
  border-bottom: 1px solid rgba(58, 77, 96, 0.15);
  font-weight: 600;
  color: rgba(58, 77, 96, 0.8);
}
.base .heading .label {
  flex-grow: 1;
  padding: 9px 10px 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.base .item {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-top: 1px solid transparent;
  border-bottom: 1px solid rgba(58, 77, 96, 0.1);
  background-color: #ffffff;
  transition: color 0.2s ease-in-out;
  user-select: none;
  padding: 7px 20px 9px 6px;
}
.base .item .symbol {
  position: absolute;
  top: -1px;
  right: 10px;
  height: 100%;
}
.base .item .symbol .wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.base .item .append {
  padding-right: 7px;
}
.base .item:last-child {
  border-bottom: none;
}
.base .item:hover {
  cursor: pointer;
  background: rgba(58, 77, 96, 0.03);
  color: #3a4d60;
}
.base .item:active {
  background-color: #ffffff;
}