/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   ARROW
   =================================================================== */
.base {
  transform-origin: center bottom;
}
.base svg {
  display: block;
  height: auto;
  width: 100%;
}
.base.colorContrast svg {
  fill: #ffffff;
}
.base.colorPrimary svg {
  fill: #1761a6;
}