/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   LAYOUT FULL
   =================================================================== */
.base {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  background: #eaf0f5;
  z-index: 600;
  overflow: hidden;
}
.base .wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background: #ffffff;
}
.base .wrapper .content {
  margin: 17vh auto;
  width: 100%;
}