/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.footer {
  flex-shrink: 0;
  padding: 20px;
  border-top: 1px solid rgba(58, 77, 96, 0.15);
}