/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.base img {
  animation-name: backgroundMove;
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes backgroundMove {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.35);
  }
  100% {
    transform: scale(1);
  }
}