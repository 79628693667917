/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   GRID COL
   =================================================================== */
.gutter_ver_tiny {
  padding-left: calc(0.25rem / 2);
  padding-right: calc(0.25rem / 2);
}

.gutter_hor_tiny {
  margin-top: 0.25rem;
}

.gutter_ver_small {
  padding-left: calc(10px / 2);
  padding-right: calc(10px / 2);
}

.gutter_hor_small {
  margin-top: 10px;
}

.gutter_ver_regular {
  padding-left: calc(20px / 2);
  padding-right: calc(20px / 2);
}

.gutter_hor_regular {
  margin-top: 20px;
}

.gutter_ver_large {
  padding-left: calc(30px / 2);
  padding-right: calc(30px / 2);
}

.gutter_hor_large {
  margin-top: 30px;
}

.grow {
  flex-grow: 1;
}

.noShrink {
  flex-shrink: 0;
}

.compact {
  line-height: 0;
}

.align_hor_center {
  display: flex;
  justify-content: center;
}

.align_hor_right {
  display: flex;
  justify-content: flex-end;
}

.align_hor_left {
  display: flex;
  justify-content: flex-start;
}

.align_ver_center {
  display: flex;
  align-items: center;
}

.align_ver_bottom {
  display: flex;
  align-items: flex-end;
}

.align_ver_top {
  display: flex;
  align-items: flex-start;
}

.col_1, .col_xs_1, .col_sm_1, .col_md_1, .col_lg_1, .col_2, .col_xs_2, .col_sm_2, .col_md_2, .col_lg_2, .col_3, .col_xs_3, .col_sm_3, .col_md_3, .col_lg_3, .col_4, .col_xs_4, .col_sm_4, .col_md_4, .col_lg_4, .col_5, .col_xs_5, .col_sm_5, .col_md_5, .col_lg_5, .col_6, .col_xs_6, .col_sm_6, .col_md_6, .col_lg_6, .col_7, .col_xs_7, .col_sm_7, .col_md_7, .col_lg_7, .col_8, .col_xs_8, .col_sm_8, .col_md_8, .col_lg_8, .col_9, .col_xs_9, .col_sm_9, .col_md_9, .col_lg_9, .col_10, .col_xs_10, .col_sm_10, .col_md_10, .col_lg_10, .col_11, .col_xs_11, .col_sm_11, .col_md_11, .col_lg_11, .col_12, .col_xs_12, .col_sm_12, .col_md_12, .col_lg_12, .col_13, .col_xs_13, .col_sm_13, .col_md_13, .col_lg_13, .col_14, .col_xs_14, .col_sm_14, .col_md_14, .col_lg_14, .col_15, .col_xs_15, .col_sm_15, .col_md_15, .col_lg_15, .col_16, .col_xs_16, .col_sm_16, .col_md_16, .col_lg_16, .col_17, .col_xs_17, .col_sm_17, .col_md_17, .col_lg_17, .col_18, .col_xs_18, .col_sm_18, .col_md_18, .col_lg_18, .col_19, .col_xs_19, .col_sm_19, .col_md_19, .col_lg_19, .col_20, .col_xs_20, .col_sm_20, .col_md_20, .col_lg_20, .col_21, .col_xs_21, .col_sm_21, .col_md_21, .col_lg_21, .col_22, .col_xs_22, .col_sm_22, .col_md_22, .col_lg_22, .col_23, .col_xs_23, .col_sm_23, .col_md_23, .col_lg_23, .col_24, .col_xs_24, .col_sm_24, .col_md_24, .col_lg_24 {
  position: relative;
  min-height: 1px;
  width: 100%;
  flex-shrink: 1;
}

.col_24 {
  width: 100%;
}

.col_push_24 {
  left: 100%;
}

.col_pull_24 {
  right: 100%;
}

.col_offset_24 {
  margin-left: 100%;
}

.col_order_24 {
  order: 24;
}

.col_23 {
  width: 95.8333333333%;
}

.col_push_23 {
  left: 95.8333333333%;
}

.col_pull_23 {
  right: 95.8333333333%;
}

.col_offset_23 {
  margin-left: 95.8333333333%;
}

.col_order_23 {
  order: 23;
}

.col_22 {
  width: 91.6666666667%;
}

.col_push_22 {
  left: 91.6666666667%;
}

.col_pull_22 {
  right: 91.6666666667%;
}

.col_offset_22 {
  margin-left: 91.6666666667%;
}

.col_order_22 {
  order: 22;
}

.col_21 {
  width: 87.5%;
}

.col_push_21 {
  left: 87.5%;
}

.col_pull_21 {
  right: 87.5%;
}

.col_offset_21 {
  margin-left: 87.5%;
}

.col_order_21 {
  order: 21;
}

.col_20 {
  width: 83.3333333333%;
}

.col_push_20 {
  left: 83.3333333333%;
}

.col_pull_20 {
  right: 83.3333333333%;
}

.col_offset_20 {
  margin-left: 83.3333333333%;
}

.col_order_20 {
  order: 20;
}

.col_19 {
  width: 79.1666666667%;
}

.col_push_19 {
  left: 79.1666666667%;
}

.col_pull_19 {
  right: 79.1666666667%;
}

.col_offset_19 {
  margin-left: 79.1666666667%;
}

.col_order_19 {
  order: 19;
}

.col_18 {
  width: 75%;
}

.col_push_18 {
  left: 75%;
}

.col_pull_18 {
  right: 75%;
}

.col_offset_18 {
  margin-left: 75%;
}

.col_order_18 {
  order: 18;
}

.col_17 {
  width: 70.8333333333%;
}

.col_push_17 {
  left: 70.8333333333%;
}

.col_pull_17 {
  right: 70.8333333333%;
}

.col_offset_17 {
  margin-left: 70.8333333333%;
}

.col_order_17 {
  order: 17;
}

.col_16 {
  width: 66.6666666667%;
}

.col_push_16 {
  left: 66.6666666667%;
}

.col_pull_16 {
  right: 66.6666666667%;
}

.col_offset_16 {
  margin-left: 66.6666666667%;
}

.col_order_16 {
  order: 16;
}

.col_15 {
  width: 62.5%;
}

.col_push_15 {
  left: 62.5%;
}

.col_pull_15 {
  right: 62.5%;
}

.col_offset_15 {
  margin-left: 62.5%;
}

.col_order_15 {
  order: 15;
}

.col_14 {
  width: 58.3333333333%;
}

.col_push_14 {
  left: 58.3333333333%;
}

.col_pull_14 {
  right: 58.3333333333%;
}

.col_offset_14 {
  margin-left: 58.3333333333%;
}

.col_order_14 {
  order: 14;
}

.col_13 {
  width: 54.1666666667%;
}

.col_push_13 {
  left: 54.1666666667%;
}

.col_pull_13 {
  right: 54.1666666667%;
}

.col_offset_13 {
  margin-left: 54.1666666667%;
}

.col_order_13 {
  order: 13;
}

.col_12 {
  width: 50%;
}

.col_push_12 {
  left: 50%;
}

.col_pull_12 {
  right: 50%;
}

.col_offset_12 {
  margin-left: 50%;
}

.col_order_12 {
  order: 12;
}

.col_11 {
  width: 45.8333333333%;
}

.col_push_11 {
  left: 45.8333333333%;
}

.col_pull_11 {
  right: 45.8333333333%;
}

.col_offset_11 {
  margin-left: 45.8333333333%;
}

.col_order_11 {
  order: 11;
}

.col_10 {
  width: 41.6666666667%;
}

.col_push_10 {
  left: 41.6666666667%;
}

.col_pull_10 {
  right: 41.6666666667%;
}

.col_offset_10 {
  margin-left: 41.6666666667%;
}

.col_order_10 {
  order: 10;
}

.col_9 {
  width: 37.5%;
}

.col_push_9 {
  left: 37.5%;
}

.col_pull_9 {
  right: 37.5%;
}

.col_offset_9 {
  margin-left: 37.5%;
}

.col_order_9 {
  order: 9;
}

.col_8 {
  width: 33.3333333333%;
}

.col_push_8 {
  left: 33.3333333333%;
}

.col_pull_8 {
  right: 33.3333333333%;
}

.col_offset_8 {
  margin-left: 33.3333333333%;
}

.col_order_8 {
  order: 8;
}

.col_7 {
  width: 29.1666666667%;
}

.col_push_7 {
  left: 29.1666666667%;
}

.col_pull_7 {
  right: 29.1666666667%;
}

.col_offset_7 {
  margin-left: 29.1666666667%;
}

.col_order_7 {
  order: 7;
}

.col_6 {
  width: 25%;
}

.col_push_6 {
  left: 25%;
}

.col_pull_6 {
  right: 25%;
}

.col_offset_6 {
  margin-left: 25%;
}

.col_order_6 {
  order: 6;
}

.col_5 {
  width: 20.8333333333%;
}

.col_push_5 {
  left: 20.8333333333%;
}

.col_pull_5 {
  right: 20.8333333333%;
}

.col_offset_5 {
  margin-left: 20.8333333333%;
}

.col_order_5 {
  order: 5;
}

.col_4 {
  width: 16.6666666667%;
}

.col_push_4 {
  left: 16.6666666667%;
}

.col_pull_4 {
  right: 16.6666666667%;
}

.col_offset_4 {
  margin-left: 16.6666666667%;
}

.col_order_4 {
  order: 4;
}

.col_3 {
  width: 12.5%;
}

.col_push_3 {
  left: 12.5%;
}

.col_pull_3 {
  right: 12.5%;
}

.col_offset_3 {
  margin-left: 12.5%;
}

.col_order_3 {
  order: 3;
}

.col_2 {
  width: 8.3333333333%;
}

.col_push_2 {
  left: 8.3333333333%;
}

.col_pull_2 {
  right: 8.3333333333%;
}

.col_offset_2 {
  margin-left: 8.3333333333%;
}

.col_order_2 {
  order: 2;
}

.col_1 {
  width: 4.1666666667%;
}

.col_push_1 {
  left: 4.1666666667%;
}

.col_pull_1 {
  right: 4.1666666667%;
}

.col_offset_1 {
  margin-left: 4.1666666667%;
}

.col_order_1 {
  order: 1;
}

.col_0 {
  display: none;
}

.col_push_0 {
  left: auto;
}

.col_pull_0 {
  right: auto;
}

.col_push_0 {
  left: auto;
}

.col_pull_0 {
  right: auto;
}

.col_offset_0 {
  margin-left: 0;
}

.col_order_0 {
  order: 0;
}

.col_xs_24 {
  width: 100%;
}

.col_xs_push_24 {
  left: 100%;
}

.col_xs_pull_24 {
  right: 100%;
}

.col_xs_offset_24 {
  margin-left: 100%;
}

.col_xs_order_24 {
  order: 24;
}

.col_xs_23 {
  width: 95.8333333333%;
}

.col_xs_push_23 {
  left: 95.8333333333%;
}

.col_xs_pull_23 {
  right: 95.8333333333%;
}

.col_xs_offset_23 {
  margin-left: 95.8333333333%;
}

.col_xs_order_23 {
  order: 23;
}

.col_xs_22 {
  width: 91.6666666667%;
}

.col_xs_push_22 {
  left: 91.6666666667%;
}

.col_xs_pull_22 {
  right: 91.6666666667%;
}

.col_xs_offset_22 {
  margin-left: 91.6666666667%;
}

.col_xs_order_22 {
  order: 22;
}

.col_xs_21 {
  width: 87.5%;
}

.col_xs_push_21 {
  left: 87.5%;
}

.col_xs_pull_21 {
  right: 87.5%;
}

.col_xs_offset_21 {
  margin-left: 87.5%;
}

.col_xs_order_21 {
  order: 21;
}

.col_xs_20 {
  width: 83.3333333333%;
}

.col_xs_push_20 {
  left: 83.3333333333%;
}

.col_xs_pull_20 {
  right: 83.3333333333%;
}

.col_xs_offset_20 {
  margin-left: 83.3333333333%;
}

.col_xs_order_20 {
  order: 20;
}

.col_xs_19 {
  width: 79.1666666667%;
}

.col_xs_push_19 {
  left: 79.1666666667%;
}

.col_xs_pull_19 {
  right: 79.1666666667%;
}

.col_xs_offset_19 {
  margin-left: 79.1666666667%;
}

.col_xs_order_19 {
  order: 19;
}

.col_xs_18 {
  width: 75%;
}

.col_xs_push_18 {
  left: 75%;
}

.col_xs_pull_18 {
  right: 75%;
}

.col_xs_offset_18 {
  margin-left: 75%;
}

.col_xs_order_18 {
  order: 18;
}

.col_xs_17 {
  width: 70.8333333333%;
}

.col_xs_push_17 {
  left: 70.8333333333%;
}

.col_xs_pull_17 {
  right: 70.8333333333%;
}

.col_xs_offset_17 {
  margin-left: 70.8333333333%;
}

.col_xs_order_17 {
  order: 17;
}

.col_xs_16 {
  width: 66.6666666667%;
}

.col_xs_push_16 {
  left: 66.6666666667%;
}

.col_xs_pull_16 {
  right: 66.6666666667%;
}

.col_xs_offset_16 {
  margin-left: 66.6666666667%;
}

.col_xs_order_16 {
  order: 16;
}

.col_xs_15 {
  width: 62.5%;
}

.col_xs_push_15 {
  left: 62.5%;
}

.col_xs_pull_15 {
  right: 62.5%;
}

.col_xs_offset_15 {
  margin-left: 62.5%;
}

.col_xs_order_15 {
  order: 15;
}

.col_xs_14 {
  width: 58.3333333333%;
}

.col_xs_push_14 {
  left: 58.3333333333%;
}

.col_xs_pull_14 {
  right: 58.3333333333%;
}

.col_xs_offset_14 {
  margin-left: 58.3333333333%;
}

.col_xs_order_14 {
  order: 14;
}

.col_xs_13 {
  width: 54.1666666667%;
}

.col_xs_push_13 {
  left: 54.1666666667%;
}

.col_xs_pull_13 {
  right: 54.1666666667%;
}

.col_xs_offset_13 {
  margin-left: 54.1666666667%;
}

.col_xs_order_13 {
  order: 13;
}

.col_xs_12 {
  width: 50%;
}

.col_xs_push_12 {
  left: 50%;
}

.col_xs_pull_12 {
  right: 50%;
}

.col_xs_offset_12 {
  margin-left: 50%;
}

.col_xs_order_12 {
  order: 12;
}

.col_xs_11 {
  width: 45.8333333333%;
}

.col_xs_push_11 {
  left: 45.8333333333%;
}

.col_xs_pull_11 {
  right: 45.8333333333%;
}

.col_xs_offset_11 {
  margin-left: 45.8333333333%;
}

.col_xs_order_11 {
  order: 11;
}

.col_xs_10 {
  width: 41.6666666667%;
}

.col_xs_push_10 {
  left: 41.6666666667%;
}

.col_xs_pull_10 {
  right: 41.6666666667%;
}

.col_xs_offset_10 {
  margin-left: 41.6666666667%;
}

.col_xs_order_10 {
  order: 10;
}

.col_xs_9 {
  width: 37.5%;
}

.col_xs_push_9 {
  left: 37.5%;
}

.col_xs_pull_9 {
  right: 37.5%;
}

.col_xs_offset_9 {
  margin-left: 37.5%;
}

.col_xs_order_9 {
  order: 9;
}

.col_xs_8 {
  width: 33.3333333333%;
}

.col_xs_push_8 {
  left: 33.3333333333%;
}

.col_xs_pull_8 {
  right: 33.3333333333%;
}

.col_xs_offset_8 {
  margin-left: 33.3333333333%;
}

.col_xs_order_8 {
  order: 8;
}

.col_xs_7 {
  width: 29.1666666667%;
}

.col_xs_push_7 {
  left: 29.1666666667%;
}

.col_xs_pull_7 {
  right: 29.1666666667%;
}

.col_xs_offset_7 {
  margin-left: 29.1666666667%;
}

.col_xs_order_7 {
  order: 7;
}

.col_xs_6 {
  width: 25%;
}

.col_xs_push_6 {
  left: 25%;
}

.col_xs_pull_6 {
  right: 25%;
}

.col_xs_offset_6 {
  margin-left: 25%;
}

.col_xs_order_6 {
  order: 6;
}

.col_xs_5 {
  width: 20.8333333333%;
}

.col_xs_push_5 {
  left: 20.8333333333%;
}

.col_xs_pull_5 {
  right: 20.8333333333%;
}

.col_xs_offset_5 {
  margin-left: 20.8333333333%;
}

.col_xs_order_5 {
  order: 5;
}

.col_xs_4 {
  width: 16.6666666667%;
}

.col_xs_push_4 {
  left: 16.6666666667%;
}

.col_xs_pull_4 {
  right: 16.6666666667%;
}

.col_xs_offset_4 {
  margin-left: 16.6666666667%;
}

.col_xs_order_4 {
  order: 4;
}

.col_xs_3 {
  width: 12.5%;
}

.col_xs_push_3 {
  left: 12.5%;
}

.col_xs_pull_3 {
  right: 12.5%;
}

.col_xs_offset_3 {
  margin-left: 12.5%;
}

.col_xs_order_3 {
  order: 3;
}

.col_xs_2 {
  width: 8.3333333333%;
}

.col_xs_push_2 {
  left: 8.3333333333%;
}

.col_xs_pull_2 {
  right: 8.3333333333%;
}

.col_xs_offset_2 {
  margin-left: 8.3333333333%;
}

.col_xs_order_2 {
  order: 2;
}

.col_xs_1 {
  width: 4.1666666667%;
}

.col_xs_push_1 {
  left: 4.1666666667%;
}

.col_xs_pull_1 {
  right: 4.1666666667%;
}

.col_xs_offset_1 {
  margin-left: 4.1666666667%;
}

.col_xs_order_1 {
  order: 1;
}

.col_xs_0 {
  display: none;
}

.col_push_0 {
  left: auto;
}

.col_pull_0 {
  right: auto;
}

.col_xs_push_0 {
  left: auto;
}

.col_xs_pull_0 {
  right: auto;
}

.col_xs_offset_0 {
  margin-left: 0;
}

.col_xs_order_0 {
  order: 0;
}

@media (min-width: 600px) {
  .col_sm_24 {
    width: 100%;
  }

  .col_sm_push_24 {
    left: 100%;
  }

  .col_sm_pull_24 {
    right: 100%;
  }

  .col_sm_offset_24 {
    margin-left: 100%;
  }

  .col_sm_order_24 {
    order: 24;
  }

  .col_sm_23 {
    width: 95.8333333333%;
  }

  .col_sm_push_23 {
    left: 95.8333333333%;
  }

  .col_sm_pull_23 {
    right: 95.8333333333%;
  }

  .col_sm_offset_23 {
    margin-left: 95.8333333333%;
  }

  .col_sm_order_23 {
    order: 23;
  }

  .col_sm_22 {
    width: 91.6666666667%;
  }

  .col_sm_push_22 {
    left: 91.6666666667%;
  }

  .col_sm_pull_22 {
    right: 91.6666666667%;
  }

  .col_sm_offset_22 {
    margin-left: 91.6666666667%;
  }

  .col_sm_order_22 {
    order: 22;
  }

  .col_sm_21 {
    width: 87.5%;
  }

  .col_sm_push_21 {
    left: 87.5%;
  }

  .col_sm_pull_21 {
    right: 87.5%;
  }

  .col_sm_offset_21 {
    margin-left: 87.5%;
  }

  .col_sm_order_21 {
    order: 21;
  }

  .col_sm_20 {
    width: 83.3333333333%;
  }

  .col_sm_push_20 {
    left: 83.3333333333%;
  }

  .col_sm_pull_20 {
    right: 83.3333333333%;
  }

  .col_sm_offset_20 {
    margin-left: 83.3333333333%;
  }

  .col_sm_order_20 {
    order: 20;
  }

  .col_sm_19 {
    width: 79.1666666667%;
  }

  .col_sm_push_19 {
    left: 79.1666666667%;
  }

  .col_sm_pull_19 {
    right: 79.1666666667%;
  }

  .col_sm_offset_19 {
    margin-left: 79.1666666667%;
  }

  .col_sm_order_19 {
    order: 19;
  }

  .col_sm_18 {
    width: 75%;
  }

  .col_sm_push_18 {
    left: 75%;
  }

  .col_sm_pull_18 {
    right: 75%;
  }

  .col_sm_offset_18 {
    margin-left: 75%;
  }

  .col_sm_order_18 {
    order: 18;
  }

  .col_sm_17 {
    width: 70.8333333333%;
  }

  .col_sm_push_17 {
    left: 70.8333333333%;
  }

  .col_sm_pull_17 {
    right: 70.8333333333%;
  }

  .col_sm_offset_17 {
    margin-left: 70.8333333333%;
  }

  .col_sm_order_17 {
    order: 17;
  }

  .col_sm_16 {
    width: 66.6666666667%;
  }

  .col_sm_push_16 {
    left: 66.6666666667%;
  }

  .col_sm_pull_16 {
    right: 66.6666666667%;
  }

  .col_sm_offset_16 {
    margin-left: 66.6666666667%;
  }

  .col_sm_order_16 {
    order: 16;
  }

  .col_sm_15 {
    width: 62.5%;
  }

  .col_sm_push_15 {
    left: 62.5%;
  }

  .col_sm_pull_15 {
    right: 62.5%;
  }

  .col_sm_offset_15 {
    margin-left: 62.5%;
  }

  .col_sm_order_15 {
    order: 15;
  }

  .col_sm_14 {
    width: 58.3333333333%;
  }

  .col_sm_push_14 {
    left: 58.3333333333%;
  }

  .col_sm_pull_14 {
    right: 58.3333333333%;
  }

  .col_sm_offset_14 {
    margin-left: 58.3333333333%;
  }

  .col_sm_order_14 {
    order: 14;
  }

  .col_sm_13 {
    width: 54.1666666667%;
  }

  .col_sm_push_13 {
    left: 54.1666666667%;
  }

  .col_sm_pull_13 {
    right: 54.1666666667%;
  }

  .col_sm_offset_13 {
    margin-left: 54.1666666667%;
  }

  .col_sm_order_13 {
    order: 13;
  }

  .col_sm_12 {
    width: 50%;
  }

  .col_sm_push_12 {
    left: 50%;
  }

  .col_sm_pull_12 {
    right: 50%;
  }

  .col_sm_offset_12 {
    margin-left: 50%;
  }

  .col_sm_order_12 {
    order: 12;
  }

  .col_sm_11 {
    width: 45.8333333333%;
  }

  .col_sm_push_11 {
    left: 45.8333333333%;
  }

  .col_sm_pull_11 {
    right: 45.8333333333%;
  }

  .col_sm_offset_11 {
    margin-left: 45.8333333333%;
  }

  .col_sm_order_11 {
    order: 11;
  }

  .col_sm_10 {
    width: 41.6666666667%;
  }

  .col_sm_push_10 {
    left: 41.6666666667%;
  }

  .col_sm_pull_10 {
    right: 41.6666666667%;
  }

  .col_sm_offset_10 {
    margin-left: 41.6666666667%;
  }

  .col_sm_order_10 {
    order: 10;
  }

  .col_sm_9 {
    width: 37.5%;
  }

  .col_sm_push_9 {
    left: 37.5%;
  }

  .col_sm_pull_9 {
    right: 37.5%;
  }

  .col_sm_offset_9 {
    margin-left: 37.5%;
  }

  .col_sm_order_9 {
    order: 9;
  }

  .col_sm_8 {
    width: 33.3333333333%;
  }

  .col_sm_push_8 {
    left: 33.3333333333%;
  }

  .col_sm_pull_8 {
    right: 33.3333333333%;
  }

  .col_sm_offset_8 {
    margin-left: 33.3333333333%;
  }

  .col_sm_order_8 {
    order: 8;
  }

  .col_sm_7 {
    width: 29.1666666667%;
  }

  .col_sm_push_7 {
    left: 29.1666666667%;
  }

  .col_sm_pull_7 {
    right: 29.1666666667%;
  }

  .col_sm_offset_7 {
    margin-left: 29.1666666667%;
  }

  .col_sm_order_7 {
    order: 7;
  }

  .col_sm_6 {
    width: 25%;
  }

  .col_sm_push_6 {
    left: 25%;
  }

  .col_sm_pull_6 {
    right: 25%;
  }

  .col_sm_offset_6 {
    margin-left: 25%;
  }

  .col_sm_order_6 {
    order: 6;
  }

  .col_sm_5 {
    width: 20.8333333333%;
  }

  .col_sm_push_5 {
    left: 20.8333333333%;
  }

  .col_sm_pull_5 {
    right: 20.8333333333%;
  }

  .col_sm_offset_5 {
    margin-left: 20.8333333333%;
  }

  .col_sm_order_5 {
    order: 5;
  }

  .col_sm_4 {
    width: 16.6666666667%;
  }

  .col_sm_push_4 {
    left: 16.6666666667%;
  }

  .col_sm_pull_4 {
    right: 16.6666666667%;
  }

  .col_sm_offset_4 {
    margin-left: 16.6666666667%;
  }

  .col_sm_order_4 {
    order: 4;
  }

  .col_sm_3 {
    width: 12.5%;
  }

  .col_sm_push_3 {
    left: 12.5%;
  }

  .col_sm_pull_3 {
    right: 12.5%;
  }

  .col_sm_offset_3 {
    margin-left: 12.5%;
  }

  .col_sm_order_3 {
    order: 3;
  }

  .col_sm_2 {
    width: 8.3333333333%;
  }

  .col_sm_push_2 {
    left: 8.3333333333%;
  }

  .col_sm_pull_2 {
    right: 8.3333333333%;
  }

  .col_sm_offset_2 {
    margin-left: 8.3333333333%;
  }

  .col_sm_order_2 {
    order: 2;
  }

  .col_sm_1 {
    width: 4.1666666667%;
  }

  .col_sm_push_1 {
    left: 4.1666666667%;
  }

  .col_sm_pull_1 {
    right: 4.1666666667%;
  }

  .col_sm_offset_1 {
    margin-left: 4.1666666667%;
  }

  .col_sm_order_1 {
    order: 1;
  }

  .col_sm_0 {
    display: none;
  }

  .col_push_0 {
    left: auto;
  }

  .col_pull_0 {
    right: auto;
  }

  .col_sm_push_0 {
    left: auto;
  }

  .col_sm_pull_0 {
    right: auto;
  }

  .col_sm_offset_0 {
    margin-left: 0;
  }

  .col_sm_order_0 {
    order: 0;
  }
}
@media (min-width: 900px) {
  .col_md_24 {
    width: 100%;
  }

  .col_md_push_24 {
    left: 100%;
  }

  .col_md_pull_24 {
    right: 100%;
  }

  .col_md_offset_24 {
    margin-left: 100%;
  }

  .col_md_order_24 {
    order: 24;
  }

  .col_md_23 {
    width: 95.8333333333%;
  }

  .col_md_push_23 {
    left: 95.8333333333%;
  }

  .col_md_pull_23 {
    right: 95.8333333333%;
  }

  .col_md_offset_23 {
    margin-left: 95.8333333333%;
  }

  .col_md_order_23 {
    order: 23;
  }

  .col_md_22 {
    width: 91.6666666667%;
  }

  .col_md_push_22 {
    left: 91.6666666667%;
  }

  .col_md_pull_22 {
    right: 91.6666666667%;
  }

  .col_md_offset_22 {
    margin-left: 91.6666666667%;
  }

  .col_md_order_22 {
    order: 22;
  }

  .col_md_21 {
    width: 87.5%;
  }

  .col_md_push_21 {
    left: 87.5%;
  }

  .col_md_pull_21 {
    right: 87.5%;
  }

  .col_md_offset_21 {
    margin-left: 87.5%;
  }

  .col_md_order_21 {
    order: 21;
  }

  .col_md_20 {
    width: 83.3333333333%;
  }

  .col_md_push_20 {
    left: 83.3333333333%;
  }

  .col_md_pull_20 {
    right: 83.3333333333%;
  }

  .col_md_offset_20 {
    margin-left: 83.3333333333%;
  }

  .col_md_order_20 {
    order: 20;
  }

  .col_md_19 {
    width: 79.1666666667%;
  }

  .col_md_push_19 {
    left: 79.1666666667%;
  }

  .col_md_pull_19 {
    right: 79.1666666667%;
  }

  .col_md_offset_19 {
    margin-left: 79.1666666667%;
  }

  .col_md_order_19 {
    order: 19;
  }

  .col_md_18 {
    width: 75%;
  }

  .col_md_push_18 {
    left: 75%;
  }

  .col_md_pull_18 {
    right: 75%;
  }

  .col_md_offset_18 {
    margin-left: 75%;
  }

  .col_md_order_18 {
    order: 18;
  }

  .col_md_17 {
    width: 70.8333333333%;
  }

  .col_md_push_17 {
    left: 70.8333333333%;
  }

  .col_md_pull_17 {
    right: 70.8333333333%;
  }

  .col_md_offset_17 {
    margin-left: 70.8333333333%;
  }

  .col_md_order_17 {
    order: 17;
  }

  .col_md_16 {
    width: 66.6666666667%;
  }

  .col_md_push_16 {
    left: 66.6666666667%;
  }

  .col_md_pull_16 {
    right: 66.6666666667%;
  }

  .col_md_offset_16 {
    margin-left: 66.6666666667%;
  }

  .col_md_order_16 {
    order: 16;
  }

  .col_md_15 {
    width: 62.5%;
  }

  .col_md_push_15 {
    left: 62.5%;
  }

  .col_md_pull_15 {
    right: 62.5%;
  }

  .col_md_offset_15 {
    margin-left: 62.5%;
  }

  .col_md_order_15 {
    order: 15;
  }

  .col_md_14 {
    width: 58.3333333333%;
  }

  .col_md_push_14 {
    left: 58.3333333333%;
  }

  .col_md_pull_14 {
    right: 58.3333333333%;
  }

  .col_md_offset_14 {
    margin-left: 58.3333333333%;
  }

  .col_md_order_14 {
    order: 14;
  }

  .col_md_13 {
    width: 54.1666666667%;
  }

  .col_md_push_13 {
    left: 54.1666666667%;
  }

  .col_md_pull_13 {
    right: 54.1666666667%;
  }

  .col_md_offset_13 {
    margin-left: 54.1666666667%;
  }

  .col_md_order_13 {
    order: 13;
  }

  .col_md_12 {
    width: 50%;
  }

  .col_md_push_12 {
    left: 50%;
  }

  .col_md_pull_12 {
    right: 50%;
  }

  .col_md_offset_12 {
    margin-left: 50%;
  }

  .col_md_order_12 {
    order: 12;
  }

  .col_md_11 {
    width: 45.8333333333%;
  }

  .col_md_push_11 {
    left: 45.8333333333%;
  }

  .col_md_pull_11 {
    right: 45.8333333333%;
  }

  .col_md_offset_11 {
    margin-left: 45.8333333333%;
  }

  .col_md_order_11 {
    order: 11;
  }

  .col_md_10 {
    width: 41.6666666667%;
  }

  .col_md_push_10 {
    left: 41.6666666667%;
  }

  .col_md_pull_10 {
    right: 41.6666666667%;
  }

  .col_md_offset_10 {
    margin-left: 41.6666666667%;
  }

  .col_md_order_10 {
    order: 10;
  }

  .col_md_9 {
    width: 37.5%;
  }

  .col_md_push_9 {
    left: 37.5%;
  }

  .col_md_pull_9 {
    right: 37.5%;
  }

  .col_md_offset_9 {
    margin-left: 37.5%;
  }

  .col_md_order_9 {
    order: 9;
  }

  .col_md_8 {
    width: 33.3333333333%;
  }

  .col_md_push_8 {
    left: 33.3333333333%;
  }

  .col_md_pull_8 {
    right: 33.3333333333%;
  }

  .col_md_offset_8 {
    margin-left: 33.3333333333%;
  }

  .col_md_order_8 {
    order: 8;
  }

  .col_md_7 {
    width: 29.1666666667%;
  }

  .col_md_push_7 {
    left: 29.1666666667%;
  }

  .col_md_pull_7 {
    right: 29.1666666667%;
  }

  .col_md_offset_7 {
    margin-left: 29.1666666667%;
  }

  .col_md_order_7 {
    order: 7;
  }

  .col_md_6 {
    width: 25%;
  }

  .col_md_push_6 {
    left: 25%;
  }

  .col_md_pull_6 {
    right: 25%;
  }

  .col_md_offset_6 {
    margin-left: 25%;
  }

  .col_md_order_6 {
    order: 6;
  }

  .col_md_5 {
    width: 20.8333333333%;
  }

  .col_md_push_5 {
    left: 20.8333333333%;
  }

  .col_md_pull_5 {
    right: 20.8333333333%;
  }

  .col_md_offset_5 {
    margin-left: 20.8333333333%;
  }

  .col_md_order_5 {
    order: 5;
  }

  .col_md_4 {
    width: 16.6666666667%;
  }

  .col_md_push_4 {
    left: 16.6666666667%;
  }

  .col_md_pull_4 {
    right: 16.6666666667%;
  }

  .col_md_offset_4 {
    margin-left: 16.6666666667%;
  }

  .col_md_order_4 {
    order: 4;
  }

  .col_md_3 {
    width: 12.5%;
  }

  .col_md_push_3 {
    left: 12.5%;
  }

  .col_md_pull_3 {
    right: 12.5%;
  }

  .col_md_offset_3 {
    margin-left: 12.5%;
  }

  .col_md_order_3 {
    order: 3;
  }

  .col_md_2 {
    width: 8.3333333333%;
  }

  .col_md_push_2 {
    left: 8.3333333333%;
  }

  .col_md_pull_2 {
    right: 8.3333333333%;
  }

  .col_md_offset_2 {
    margin-left: 8.3333333333%;
  }

  .col_md_order_2 {
    order: 2;
  }

  .col_md_1 {
    width: 4.1666666667%;
  }

  .col_md_push_1 {
    left: 4.1666666667%;
  }

  .col_md_pull_1 {
    right: 4.1666666667%;
  }

  .col_md_offset_1 {
    margin-left: 4.1666666667%;
  }

  .col_md_order_1 {
    order: 1;
  }

  .col_md_0 {
    display: none;
  }

  .col_push_0 {
    left: auto;
  }

  .col_pull_0 {
    right: auto;
  }

  .col_md_push_0 {
    left: auto;
  }

  .col_md_pull_0 {
    right: auto;
  }

  .col_md_offset_0 {
    margin-left: 0;
  }

  .col_md_order_0 {
    order: 0;
  }
}
@media (min-width: 1200px) {
  .col_lg_24 {
    width: 100%;
  }

  .col_lg_push_24 {
    left: 100%;
  }

  .col_lg_pull_24 {
    right: 100%;
  }

  .col_lg_offset_24 {
    margin-left: 100%;
  }

  .col_lg_order_24 {
    order: 24;
  }

  .col_lg_23 {
    width: 95.8333333333%;
  }

  .col_lg_push_23 {
    left: 95.8333333333%;
  }

  .col_lg_pull_23 {
    right: 95.8333333333%;
  }

  .col_lg_offset_23 {
    margin-left: 95.8333333333%;
  }

  .col_lg_order_23 {
    order: 23;
  }

  .col_lg_22 {
    width: 91.6666666667%;
  }

  .col_lg_push_22 {
    left: 91.6666666667%;
  }

  .col_lg_pull_22 {
    right: 91.6666666667%;
  }

  .col_lg_offset_22 {
    margin-left: 91.6666666667%;
  }

  .col_lg_order_22 {
    order: 22;
  }

  .col_lg_21 {
    width: 87.5%;
  }

  .col_lg_push_21 {
    left: 87.5%;
  }

  .col_lg_pull_21 {
    right: 87.5%;
  }

  .col_lg_offset_21 {
    margin-left: 87.5%;
  }

  .col_lg_order_21 {
    order: 21;
  }

  .col_lg_20 {
    width: 83.3333333333%;
  }

  .col_lg_push_20 {
    left: 83.3333333333%;
  }

  .col_lg_pull_20 {
    right: 83.3333333333%;
  }

  .col_lg_offset_20 {
    margin-left: 83.3333333333%;
  }

  .col_lg_order_20 {
    order: 20;
  }

  .col_lg_19 {
    width: 79.1666666667%;
  }

  .col_lg_push_19 {
    left: 79.1666666667%;
  }

  .col_lg_pull_19 {
    right: 79.1666666667%;
  }

  .col_lg_offset_19 {
    margin-left: 79.1666666667%;
  }

  .col_lg_order_19 {
    order: 19;
  }

  .col_lg_18 {
    width: 75%;
  }

  .col_lg_push_18 {
    left: 75%;
  }

  .col_lg_pull_18 {
    right: 75%;
  }

  .col_lg_offset_18 {
    margin-left: 75%;
  }

  .col_lg_order_18 {
    order: 18;
  }

  .col_lg_17 {
    width: 70.8333333333%;
  }

  .col_lg_push_17 {
    left: 70.8333333333%;
  }

  .col_lg_pull_17 {
    right: 70.8333333333%;
  }

  .col_lg_offset_17 {
    margin-left: 70.8333333333%;
  }

  .col_lg_order_17 {
    order: 17;
  }

  .col_lg_16 {
    width: 66.6666666667%;
  }

  .col_lg_push_16 {
    left: 66.6666666667%;
  }

  .col_lg_pull_16 {
    right: 66.6666666667%;
  }

  .col_lg_offset_16 {
    margin-left: 66.6666666667%;
  }

  .col_lg_order_16 {
    order: 16;
  }

  .col_lg_15 {
    width: 62.5%;
  }

  .col_lg_push_15 {
    left: 62.5%;
  }

  .col_lg_pull_15 {
    right: 62.5%;
  }

  .col_lg_offset_15 {
    margin-left: 62.5%;
  }

  .col_lg_order_15 {
    order: 15;
  }

  .col_lg_14 {
    width: 58.3333333333%;
  }

  .col_lg_push_14 {
    left: 58.3333333333%;
  }

  .col_lg_pull_14 {
    right: 58.3333333333%;
  }

  .col_lg_offset_14 {
    margin-left: 58.3333333333%;
  }

  .col_lg_order_14 {
    order: 14;
  }

  .col_lg_13 {
    width: 54.1666666667%;
  }

  .col_lg_push_13 {
    left: 54.1666666667%;
  }

  .col_lg_pull_13 {
    right: 54.1666666667%;
  }

  .col_lg_offset_13 {
    margin-left: 54.1666666667%;
  }

  .col_lg_order_13 {
    order: 13;
  }

  .col_lg_12 {
    width: 50%;
  }

  .col_lg_push_12 {
    left: 50%;
  }

  .col_lg_pull_12 {
    right: 50%;
  }

  .col_lg_offset_12 {
    margin-left: 50%;
  }

  .col_lg_order_12 {
    order: 12;
  }

  .col_lg_11 {
    width: 45.8333333333%;
  }

  .col_lg_push_11 {
    left: 45.8333333333%;
  }

  .col_lg_pull_11 {
    right: 45.8333333333%;
  }

  .col_lg_offset_11 {
    margin-left: 45.8333333333%;
  }

  .col_lg_order_11 {
    order: 11;
  }

  .col_lg_10 {
    width: 41.6666666667%;
  }

  .col_lg_push_10 {
    left: 41.6666666667%;
  }

  .col_lg_pull_10 {
    right: 41.6666666667%;
  }

  .col_lg_offset_10 {
    margin-left: 41.6666666667%;
  }

  .col_lg_order_10 {
    order: 10;
  }

  .col_lg_9 {
    width: 37.5%;
  }

  .col_lg_push_9 {
    left: 37.5%;
  }

  .col_lg_pull_9 {
    right: 37.5%;
  }

  .col_lg_offset_9 {
    margin-left: 37.5%;
  }

  .col_lg_order_9 {
    order: 9;
  }

  .col_lg_8 {
    width: 33.3333333333%;
  }

  .col_lg_push_8 {
    left: 33.3333333333%;
  }

  .col_lg_pull_8 {
    right: 33.3333333333%;
  }

  .col_lg_offset_8 {
    margin-left: 33.3333333333%;
  }

  .col_lg_order_8 {
    order: 8;
  }

  .col_lg_7 {
    width: 29.1666666667%;
  }

  .col_lg_push_7 {
    left: 29.1666666667%;
  }

  .col_lg_pull_7 {
    right: 29.1666666667%;
  }

  .col_lg_offset_7 {
    margin-left: 29.1666666667%;
  }

  .col_lg_order_7 {
    order: 7;
  }

  .col_lg_6 {
    width: 25%;
  }

  .col_lg_push_6 {
    left: 25%;
  }

  .col_lg_pull_6 {
    right: 25%;
  }

  .col_lg_offset_6 {
    margin-left: 25%;
  }

  .col_lg_order_6 {
    order: 6;
  }

  .col_lg_5 {
    width: 20.8333333333%;
  }

  .col_lg_push_5 {
    left: 20.8333333333%;
  }

  .col_lg_pull_5 {
    right: 20.8333333333%;
  }

  .col_lg_offset_5 {
    margin-left: 20.8333333333%;
  }

  .col_lg_order_5 {
    order: 5;
  }

  .col_lg_4 {
    width: 16.6666666667%;
  }

  .col_lg_push_4 {
    left: 16.6666666667%;
  }

  .col_lg_pull_4 {
    right: 16.6666666667%;
  }

  .col_lg_offset_4 {
    margin-left: 16.6666666667%;
  }

  .col_lg_order_4 {
    order: 4;
  }

  .col_lg_3 {
    width: 12.5%;
  }

  .col_lg_push_3 {
    left: 12.5%;
  }

  .col_lg_pull_3 {
    right: 12.5%;
  }

  .col_lg_offset_3 {
    margin-left: 12.5%;
  }

  .col_lg_order_3 {
    order: 3;
  }

  .col_lg_2 {
    width: 8.3333333333%;
  }

  .col_lg_push_2 {
    left: 8.3333333333%;
  }

  .col_lg_pull_2 {
    right: 8.3333333333%;
  }

  .col_lg_offset_2 {
    margin-left: 8.3333333333%;
  }

  .col_lg_order_2 {
    order: 2;
  }

  .col_lg_1 {
    width: 4.1666666667%;
  }

  .col_lg_push_1 {
    left: 4.1666666667%;
  }

  .col_lg_pull_1 {
    right: 4.1666666667%;
  }

  .col_lg_offset_1 {
    margin-left: 4.1666666667%;
  }

  .col_lg_order_1 {
    order: 1;
  }

  .col_lg_0 {
    display: none;
  }

  .col_push_0 {
    left: auto;
  }

  .col_pull_0 {
    right: auto;
  }

  .col_lg_push_0 {
    left: auto;
  }

  .col_lg_pull_0 {
    right: auto;
  }

  .col_lg_offset_0 {
    margin-left: 0;
  }

  .col_lg_order_0 {
    order: 0;
  }
}