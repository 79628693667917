/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   BREADCRUMBS
   =================================================================== */
.base {
  overflow: hidden;
}
.base .container {
  display: flex;
  align-items: center;
}
.base .crumb {
  display: flex;
  align-items: center;
}
.base .crumb .item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.base .crumb .item .dots {
  display: none;
}
.base .crumb .item .code {
  color: rgba(58, 77, 96, 0.4);
  padding-right: 5px;
  font-weight: 400;
}
.base .crumb .item .label {
  display: inline-block;
  color: rgba(58, 77, 96, 0.4);
  white-space: nowrap;
  font-weight: 600;
}
.base .crumb .separator {
  display: flex;
  align-items: center;
  margin: 0 6px;
}
.base .crumb .separator .icon svg {
  fill: rgba(23, 97, 166, 0.8);
}
.base .crumb .separator .icon svg {
  width: 10px;
  height: 10px;
}
.base .crumb .separator .slash {
  color: #1761a6;
  transform: translateY(1px);
}
.base .crumb.clickable .item:hover {
  cursor: pointer;
}
.base .crumb.clickable .item:hover .code {
  color: #1761a6;
}
.base .crumb.clickable .item:hover .label {
  color: #3a4d60;
}
.base .crumb.active .item {
  box-shadow: inset 0 -2px 0 0 #1761a6;
}
.base .crumb:last-child .item .code {
  color: rgba(23, 97, 166, 0.8);
}
.base .crumb:last-child .item .label {
  color: #3a4d60;
}
.base .crumb:last-child .separator {
  display: none;
}
.base.compact .crumb:not(:last-child) .item {
  background-color: rgba(58, 77, 96, 0.1);
  padding: 0 5px;
  line-height: 10px;
  border-radius: 3px;
}
.base.compact .crumb:not(:last-child) .item .dots {
  display: inline-block;
}
.base.compact .crumb:not(:last-child) .item .dots svg {
  fill: rgba(58, 77, 96, 0.4);
}
.base.compact .crumb:not(:last-child) .item .code {
  display: none;
}
.base.compact .crumb:not(:last-child) .item .label {
  display: none;
}
.base.compact .crumb:not(:last-child) .item:hover {
  background-color: #1761a6;
}
.base.compact .crumb:not(:last-child) .item:hover .dots svg {
  fill: #ffffff;
}
@media (max-width: 599px) {
  .base .crumb .separator {
    margin: 0 3px;
  }
}