/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.month {
  margin: 0 auto;
}

.months {
  display: flex;
  min-height: 320px;
}

.today {
  outline: 2px solid rgba(23, 97, 166, 0.2);
  color: #1761a6;
  font-weight: 600;
  border-radius: 50%;
}

.selected:not([disabled]),
.selected:hover:hover:not([disabled]) {
  background: #36b189;
  color: #ffffff;
  font-weight: 600;
  border-radius: 50%;
}

.chevron {
  fill: #1761a6;
}

.month {
  margin: 0 auto;
  padding: 0 10px;
}