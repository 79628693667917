/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CENTER BOX TITLE
   =================================================================== */
.base {
  text-align: center;
  padding: 30px 0 0;
}
.base .title {
  font-size: 32px;
  line-height: 32px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}
.base .subtitle {
  font-size: 16px;
  color: rgba(58, 77, 96, 0.6);
  margin-top: 20px;
}
@media (max-width: 599px) {
  .base {
    padding: 20px 0 0;
  }
  .base .title {
    font-size: 28px;
  }
  .base .subtitle {
    font-size: 14px;
    line-height: 1.4;
  }
}