/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   RISK METER
   =================================================================== */
.base {
  display: inline-block;
  height: 14px;
}
.base .dot {
  vertical-align: top;
  display: inline-block;
  background-color: rgba(58, 77, 96, 0.1);
  height: 14px;
  width: 14px;
  border-radius: 14px;
  margin: 3px 0;
}
.base .dot:nth-child(2) {
  margin: 3px 5px;
}
.base.high .isOn {
  background-color: #e02447;
}
.base.high .count {
  color: #e02447;
}
.base.mid .isOn {
  background-color: #ff9100;
}
.base.mid .count {
  color: #ff9100;
}
.base.low .isOn {
  background-color: #47c680;
}
.base.low .count {
  color: #47c680;
}