/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   TABLE HEAD
   =================================================================== */
.head {
  background-color: rgba(58, 77, 96, 0.03);
}
.head > tr > th {
  text-align: left;
  padding: 10px 10px 7px;
  font-weight: 600;
  user-select: none;
  position: relative;
  border-right: 1px solid rgba(58, 77, 96, 0.05);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.head > tr > th .caret {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 10px 10px 7px;
  display: flex;
  align-items: center;
}
.head > tr > th .caret svg {
  fill: #1761a6;
}
.head > tr > th .caret svg {
  width: 10px;
  height: 10px;
}
.head > tr > th:last-child {
  border-right: none;
}
.head > tr > th.sorted {
  padding-right: 30px;
  box-shadow: 0 1px 0 0 #1761a6;
}
.head > tr > th.sortable {
  cursor: pointer;
}
.head > tr > th.sortable:hover {
  background-color: rgba(234, 240, 245, 0.5);
}