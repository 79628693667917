/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   HAZARD CARD
   =================================================================== */
.base {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  min-height: 80px;
  flex-direction: row;
}
.base .rank {
  position: relative;
  width: 22px;
  min-width: 22px;
  margin: 1px;
  border-radius: 3px 0 0 3px;
  background-color: rgba(58, 77, 96, 0.1);
  color: rgba(58, 77, 96, 0.4);
}
.base .rank .label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}
.base .rank .label .text {
  transform: rotateZ(-90deg);
}
.base .details {
  padding: 20px 10px;
  flex-grow: 1;
}
.base .details .description {
  font-weight: 600;
}
.base .details .measures {
  position: relative;
  padding-left: 20px;
  color: rgba(58, 77, 96, 0.8);
  white-space: pre-wrap;
}
.base .details .measures .icon {
  position: absolute;
  left: 0;
  top: 0;
}
.base .details .measures .icon svg {
  fill: rgba(58, 77, 96, 0.25);
}
.base .details .measures .icon svg {
  width: 14px;
  height: 14px;
}
.base .line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 10px;
  width: 5px;
  background-color: rgba(58, 77, 96, 0.2);
  bottom: -11px;
}
.base.low .rank {
  background-color: #47c680;
  color: rgba(255, 255, 255, 0.8);
}
.base.mid .rank {
  background-color: #ff9100;
  color: rgba(255, 255, 255, 0.8);
}
.base.high .rank {
  background-color: #e02447;
  color: rgba(255, 255, 255, 0.8);
}
.base.missing .rank {
  background: transparent;
  color: #d04b67;
  border: 1px dotted rgba(208, 75, 103, 0.4);
}
.base:hover:not(.preview) {
  cursor: pointer;
  border-color: rgba(58, 77, 96, 0.4);
}
.base:last-child.preview .line {
  display: none;
}