/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   SECTION
   =================================================================== */
.base {
  margin-bottom: 30px;
}
.base.divideNone {
  margin-bottom: 0;
}
.base.divideTiny {
  margin-bottom: 10px;
}
.base.divideSmall {
  margin-bottom: 20px;
}
.base.divideMedium {
  margin-bottom: 50px;
}
.base.divideLarge {
  margin-bottom: 100px;
}
.base.alignCenter {
  text-align: center;
}