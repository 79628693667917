/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  margin: 0 0 30px 0;
}
.base:first-child {
  margin-top: 0;
}
.base:last-child {
  margin-bottom: 0;
}