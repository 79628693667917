/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   TIME PICKER
   =================================================================== */
.base {
  position: relative;
}

.preview {
  display: flex;
  justify-content: center;
  padding: 20px;
  border-bottom: 1px solid rgba(58, 77, 96, 0.1);
  background-color: #1761a6;
}
.preview .segment {
  color: #ffffff;
  display: inline-block;
  background: transparent;
  font-size: 4rem;
  border: none;
  cursor: pointer;
  outline: none;
}
.preview .segment.selected {
  font-weight: 700;
  border-bottom: 1px dashed #ffffff;
}
.preview .colon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 3rem;
  padding: 0 0.3rem;
}

.picker {
  position: relative;
  height: 340px;
  overflow: hidden;
}