/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   TEXTAREA WORD
   =================================================================== */
.base {
  margin-bottom: 42px;
}
.base .textarea {
  border-radius: 3px 3px 0 3px;
}
.base .append {
  float: right;
  display: flex;
  height: 42px;
  border: 1px solid rgba(58, 77, 96, 0.2);
  border-top: none;
  border-radius: 0 0 3px 3px;
  background-color: rgba(58, 77, 96, 0.03);
  padding: 6px 10px 0;
  user-select: none;
}
.base .append .content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.base .append .lang {
  display: inline-block;
  margin-left: 5px;
  line-height: 27px;
  white-space: nowrap;
}
.base .append:hover {
  cursor: pointer;
  background-color: rgba(58, 77, 96, 0.05);
}