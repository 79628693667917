/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   STATIC TABLE
   =================================================================== */
.table {
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
}
.table thead tr th {
  background-color: rgba(58, 77, 96, 0.03);
  text-align: left;
  padding: 7px 10px;
  padding-top: 9px;
  border-top: 1px solid rgba(58, 77, 96, 0.15);
  border-bottom: 1px solid rgba(58, 77, 96, 0.15);
  border-right: 1px solid rgba(58, 77, 96, 0.1);
  font-weight: 600;
  white-space: nowrap;
}
.table thead tr th:last-child {
  border-right: 0;
}
.table tbody tr td {
  text-align: left;
  padding: 7px 10px;
  padding-top: 9px;
  border-bottom: 1px solid rgba(58, 77, 96, 0.15);
  border-right: 1px solid rgba(58, 77, 96, 0.1);
}
.table tbody tr td:last-child {
  border-right: 0;
}