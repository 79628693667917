/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CHECKBOX
   =================================================================== */
.base {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  min-height: 24px;
  user-select: none;
  vertical-align: top;
  margin: 9px 20px 9px 0;
}
.base .check {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid rgba(58, 77, 96, 0.2);
  border-radius: 3px;
  transition: all 0.2s ease;
  background-color: #ffffff;
}
.base .check .icon {
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  opacity: 0;
}
.base .check .icon svg {
  fill: #ffffff;
}
.base .label {
  flex-grow: 1;
  padding: 0 10px;
  transition: all 0.2s ease;
}
.base:hover .check {
  border-color: #1761a6;
  box-shadow: none;
}
.base:hover .label {
  color: #3a4d60;
}
.base.noMargin {
  margin: 0;
}
.base.isChecked .check {
  border-color: #1761a6;
  background-color: #1761a6;
}
.base.isChecked .check .icon {
  opacity: 1;
}
.base.isChecked .label {
  color: #3a4d60;
}
.base.isChecked:active .check {
  background-color: #1761a6;
}
.base.isDisabled {
  cursor: not-allowed;
}
.base.isDisabled .check {
  border-color: transparent;
  background-color: rgba(58, 77, 96, 0.6);
}
.base.rightToLeft {
  flex-direction: row-reverse;
  margin-right: 0;
  margin-left: 20px;
}