/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   LINK
   =================================================================== */
.base {
  display: inline-block;
  color: #1761a6;
  text-decoration: underline;
}
.base:hover {
  cursor: pointer;
  color: #4a99e2;
}
.base.silent {
  color: rgba(58, 77, 96, 0.4);
}
.base.silent:hover {
  cursor: pointer;
  color: #4a99e2;
}
.base.block {
  display: block;
}