/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   DATA TABLE
   =================================================================== */
.base {
  position: relative;
}
.base .loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.base .wrapper {
  position: relative;
  overflow: hidden;
}
.base .container {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  z-index: 1;
}
.base .overflow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  z-index: 2;
  width: 30px;
  opacity: 0;
  transition: opacity 0.2s linear;
  pointer-events: none;
}
.base .overflow:before {
  position: absolute;
  top: 0;
  content: " ";
  width: 1px;
  height: 100%;
  background: transparent;
}
.base .overflow.left {
  left: 0;
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
}
.base .overflow.left:before {
  left: 0;
}
.base .overflow.right {
  right: 0;
  background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%);
}
.base .overflow.right:before {
  right: 0;
}
.base .overflow.visible {
  opacity: 1;
}

.table {
  position: relative;
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
  font-size: 14px;
}