/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   WELL
   =================================================================== */
.base .content {
  border-radius: 3px;
  padding: 10px;
  text-align: left;
}
.base.marginSmall {
  padding: 5px;
}
.base.marginRegular {
  padding: 10px;
}
.base.variantDefault .content {
  background-color: rgba(58, 77, 96, 0.01);
  border: 1px solid rgba(58, 77, 96, 0.1);
}
.base.variantAttention .content {
  background-image: linear-gradient(90deg, #1761a6 50%, transparent 50%), linear-gradient(90deg, #1761a6 50%, transparent 50%), linear-gradient(0deg, #1761a6 50%, transparent 50%), linear-gradient(0deg, #1761a6 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 5px 1px, 5px 1px, 1px 5px, 1px 5px;
  background-position: left top, right bottom, left bottom, right top;
  animation: rotating-border-dance 0.5s infinite linear;
}
@keyframes rotating-border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 5px top, right 5px bottom, left bottom 5px, right top 5px;
  }
}