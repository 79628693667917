/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.card {
  position: relative;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  height: auto;
  max-height: 100vh;
  width: auto;
  pointer-events: none;
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: animateIn 0.3s cubic-bezier(0.62, 0.055, 0.24, 1.305) 1;
  overflow: hidden;
  aspect-ratio: 9/16;
  min-width: 319px;
  min-height: 549px;
}
.card.isDragging {
  transition: none;
}
.card.isDragging .wrapper {
  cursor: move;
}
.card.hidden {
  display: none;
}

.wrapper {
  position: absolute;
  overflow: hidden;
  background: rgba(58, 77, 96, 0.15);
  transform-origin: center center;
  touch-action: none;
  user-select: none;
  pointer-events: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@keyframes animateIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 500px) and (max-height: 900px) {
  .card {
    height: calc(var(--vh, 1vh) * 100);
    aspect-ratio: auto;
  }
}