/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   PILL SELECTOR
   =================================================================== */
.base {
  position: relative;
  margin-left: 15px;
}
.base .button {
  display: flex;
  height: 42px;
  background: rgba(58, 77, 96, 0.05);
  border-radius: 42px;
  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
}
.base .button .label {
  flex-grow: 1;
  line-height: 42px;
  padding: 0 20px 0 5px;
  color: #3a4d60;
  transition: color 0.2s ease-in-out;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.base .button .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  background-color: #1761a6;
  border-radius: 50%;
  width: 32px;
  transition: all 0.2s ease-in-out;
}
.base .button .arrow svg {
  width: 10px;
  height: 10px;
}
.base .button .arrow svg {
  fill: #ffffff;
  transition: fill 0.2s ease;
}
.base .button:hover .label {
  color: #3a4d60;
}
.base .button:hover .arrow {
  background-color: #4a99e2;
}
.base.prominent .button .label {
  color: #1761a6;
}
.base.active .button {
  background-color: #1761a6;
}
.base.active .button .label {
  color: #ffffff;
}
.base.active .button .arrow {
  background-color: #ffffff;
  transform: rotate(90deg);
}
.base.active .button .arrow svg {
  fill: #1761a6;
  transition: fill 0.2s ease;
}