/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FORM DIVIDER
   =================================================================== */
.base {
  height: 1px;
  margin-bottom: 20px;
}