/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CONTAINER
   =================================================================== */
.base {
  min-height: 100%;
  background-color: #ffffff;
}