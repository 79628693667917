/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  display: block;
  font-size: 24px;
}
.base span:first-child {
  font-weight: 200;
}
.base span:last-child {
  font-weight: 600;
}
.base.sizeMedium {
  font-size: 34px;
}
.base.sizeSmall {
  font-size: 20px;
}
.base.colorContrast {
  color: #ffffff;
}
.base.colorPrimary {
  color: #1761a6;
}