/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: relative;
  overflow: hidden;
  z-index: 500;
  width: 280px;
  min-width: 280px;
}
.base .content {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 280px;
  min-width: 280px;
  background-color: #1761a6;
}
.base .content .title {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 25px;
  background-color: #145490;
}
.base .content .wrapper {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
}
.base .overlay {
  position: relative;
  display: none;
}
@media (max-width: 899px) {
  .base {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0;
    min-width: auto;
  }
  .base .content {
    position: fixed;
    left: -285px;
    top: 0;
    bottom: 0;
    z-index: 2;
    transition: left 0.3s ease-in-out;
    width: 280px;
    min-width: 280px;
  }
  .base .overlay {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
    opacity: 0;
    transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  .base.isVisible {
    width: auto;
  }
  .base.isVisible .content {
    left: 0;
    box-shadow: 0 1px 15px 1px rgba(58, 77, 96, 0.3);
  }
  .base.isVisible .overlay {
    width: 100%;
    opacity: 1;
  }
}