/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FRONT PAGE HEADER
   =================================================================== */
.base {
  position: relative;
  padding-top: 60px;
  text-align: center;
}
.base .title {
  display: inline-block;
  margin: 0;
  padding: 60px 30px;
  text-align: center;
  font-size: 50px;
  line-height: 60px;
  max-width: 100%;
  font-weight: 900;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  letter-spacing: -1px;
}
@media (max-width: 599px) {
  .base .title {
    padding: 40px 30px 10px;
    font-size: 30px;
  }
}