/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.wheel {
  position: relative;
  margin: 0 auto;
  width: 250px;
  height: 250px;
  border-radius: 250px;
  border: 10px solid rgba(58, 77, 96, 0.1);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragger {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 250px;
  opacity: 0;
  cursor: pointer;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3a4d60;
  z-index: 1;
  border-radius: 250px;
  cursor: pointer;
  pointer-events: none;
  backface-visibility: hidden;
  perspective: 1000;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}
.spinner .line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 105px;
  transform-origin: center top;
}
.spinner .line:before {
  position: absolute;
  content: " ";
  top: 70%;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
}
.spinner .line:nth-child(odd) {
  opacity: 0.5;
}
.spinner .line:nth-child(odd):before {
  top: 80%;
}

.button {
  width: 112.5px;
  height: 112.5px;
  background-color: #1761a6;
  border-radius: 50%;
  border: none;
  z-index: 2;
  border: 2px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  color: #ffffff;
  font-size: 18px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button:active {
  background-color: #145490;
}