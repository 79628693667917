/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   SIGN UP BUTTON
   =================================================================== */
.base {
  display: inline-block !important;
  overflow: hidden;
  text-align: center;
  background-color: #1761a6;
  height: 66px;
  line-height: 66px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 28px;
  font-weight: 600;
  padding: 0 50px;
  white-space: nowrap;
}
.base:hover {
  background-color: #1a6ebc;
}
@media (max-width: 599px) {
  .base {
    font-size: 24px;
    height: 58px;
    line-height: 58px;
    padding: 0 40px;
  }
}