/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: relative;
  background: rgba(58, 77, 96, 0.1);
  aspect-ratio: 9/14;
  cursor: pointer;
  overflow: hidden;
}
.base .link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.base .status {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  right: 4em;
  z-index: 4;
}
.base .title {
  position: absolute;
  margin: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  color: #ffffff;
  padding: 3em 1em 1em;
  font-size: 1.3em;
  font-weight: 400;
  line-height: 1.3em;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  hyphens: auto;
}
.base .background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.2s ease-in-out;
  z-index: 1;
}
.base .cornerButton {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 4;
  width: 2.4em;
  height: 2.4em;
  border-radius: 2.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
}
.base .cornerButton.more {
  background-color: rgba(0, 0, 0, 0.2);
}
.base .cornerButton.more svg {
  fill: #ffffff;
}
.base .cornerButton.more.active {
  background-color: #1761a6;
}
.base .cornerButton.more.active svg {
  fill: #ffffff;
}
.base .cornerButton.add {
  background-color: #ffffff;
}
.base .cornerButton.add svg {
  fill: #36b189;
}
.base .cornerButton.add:hover {
  background-color: #36b189;
}
.base .cornerButton.add:hover svg {
  fill: #ffffff;
}
.base .cornerButton.remove {
  background-color: #ffffff;
}
.base .cornerButton.remove svg {
  fill: #d04b67;
}
.base .cornerButton.remove:hover {
  background-color: #d04b67;
}
.base .cornerButton.remove:hover svg {
  fill: #ffffff;
}
.base:hover .background {
  transform: scale(1.06);
}
.base.disabled .background {
  opacity: 0.8;
}
.base.disabled .cornerButton {
  display: none;
}
.base.disabled .background {
  opacity: 0.8;
}