/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.button {
  background-color: #f6fd9a !important;
  font-family: "Catamaran", sans-serif !important;
  border-radius: 3px !important;
  font-weight: 600 !important;
  padding: 8px 20px !important;
  margin: 0 !important;
}

.content {
  margin: 0 15px 0 0 !important;
  align-self: center;
}
.content a {
  color: #ffffff !important;
}

.container {
  background-color: #304050 !important;
  color: #ffffff !important;
  padding: 15px;
  row-gap: 15px;
}