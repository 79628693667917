/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  display: block;
}
.base .image {
  position: relative;
  margin-bottom: 30px;
  max-width: 100%;
}
.base .image img {
  width: 100%;
  margin: 0;
}
.base .image.alignCenter {
  margin: 0 auto 30px auto;
}

.tools {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: #ffffff 0px 0px 0px 2px, rgba(23, 97, 166, 0.2) 0px 0px 0px 4px;
  border-radius: 3px;
}
.tools .label {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px 8px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.2s;
}
.tools .label.visible {
  opacity: 1;
}
.tools .scaleButton {
  width: 18px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate(100%, 0);
  border: none;
  background: transparent;
  cursor: ew-resize;
  text-align: right;
  touch-action: none;
}
.tools .scaleButton .line {
  display: inline-block;
  height: 40%;
  min-height: 40px;
  width: 3px;
  border-radius: 10px;
  background: #1761a6;
}