/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   CIRCLE METER
   =================================================================== */
.base {
  position: relative;
}
.base .label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 700;
}
.base.rankLow .label {
  color: #47c680;
}
.base.rankMid .label {
  color: #ff9100;
}
.base.rankHigh .label {
  color: #e02447;
}