/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  position: relative;
  display: block;
  overflow: hidden;
}
.base .loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}
.base .loader .spinner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.base .item {
  display: block;
  width: 100%;
  border-top: 1px solid transparent;
  border-bottom: 1px solid rgba(58, 77, 96, 0.1);
  border-right: none;
  transition: color 0.2s ease-in-out;
  user-select: none;
  min-height: 42px;
  text-align: left;
  padding: 0 20px 0 6px;
}
.base .item:last-child {
  border-bottom: none;
}
.base .item:hover {
  cursor: pointer;
  background: rgba(58, 77, 96, 0.03);
  color: #3a4d60;
}
.base .item:active {
  background-color: #ffffff;
}