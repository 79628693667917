/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   BOX HEADER
   =================================================================== */
.base {
  position: relative;
  aspect-ratio: 16/9;
  background-color: rgba(58, 77, 96, 0.03);
}
.base .label {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
  padding: 7px 20px 5px;
  line-height: 1.4;
  border-radius: 100px;
  background-color: #1761a6;
  color: #ffffff;
  user-select: none;
}
.base .headline {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  padding: 30px 20px 20px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
}
@media (min-width: 600px) {
  .base.responsive .headline {
    font-size: 30px;
  }
}