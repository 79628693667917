/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   RISK CONTEXT LIST
   =================================================================== */
.base .item {
  position: relative;
  display: flex;
  overflow: hidden;
}
.base .item .details {
  padding: 20px;
  flex-grow: 1;
}
.base .item .details .title {
  font-size: 18px;
  font-weight: 600;
}
.base .item .details .description {
  color: rgba(58, 77, 96, 0.6);
}
.base .item .gauges {
  padding: 20px;
  min-width: 250px;
}
.base .item:hover {
  border-color: #1d7bd3;
  box-shadow: inset 0 1px 0 0 #ffffff, 0 0 10px rgba(23, 97, 166, 0.2);
}
@media (max-width: 599px) {
  .base .item {
    display: block;
  }
  .base .item .gauges {
    min-width: 0;
    max-width: 250px;
  }
}