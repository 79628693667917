/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   STEPS
   =================================================================== */
.base {
  position: relative;
  display: block;
  overflow: hidden;
}
.base .container {
  position: absolute;
  min-width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  transition: margin-left 0.3s ease-in-out;
}
.base .leftFade {
  z-index: 2;
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  width: 20px;
  background: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.base .rightFade {
  z-index: 2;
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  right: 0;
  width: 20px;
  background: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

/* STEP
------------------------------------ */
.step {
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  padding-left: 40px;
  padding-right: 10px;
  min-width: 240px;
}
.step .symbol {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(58, 77, 96, 0.2);
  border-radius: 50%;
  text-align: center;
  transition: all 0.3s ease;
}
.step .symbol .icon {
  display: none !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.step .symbol .icon svg {
  fill: #ffffff;
}
.step .symbol .number {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: rgba(58, 77, 96, 0.4);
  transition: all 0.3s ease;
  font-weight: 600;
}
.step .trail {
  position: absolute;
  left: 40px;
  right: 10px;
  top: 15px;
  height: 1px;
  background-color: rgba(58, 77, 96, 0.1);
  transition: background-color 0.3s ease;
}
.step .title {
  display: inline-block;
  position: relative;
  color: rgba(58, 77, 96, 0.4);
  font-size: 16px;
  font-weight: 600;
  padding: 5px 10px 5px 0;
  margin-right: 20px;
  background-color: #ffffff;
  z-index: 2;
  max-width: 150px;
}
.step .description {
  color: rgba(58, 77, 96, 0.2);
  padding-right: 20px;
}
.step:last-child .trail {
  display: none;
}
.step.isActive .symbol {
  background-color: #1761a6;
  border-color: #1761a6;
}
.step.isActive .symbol .number {
  color: #ffffff;
}
.step.isActive .title {
  color: #3a4d60;
}
.step.isActive .description {
  color: rgba(58, 77, 96, 0.6);
}
.step.isComplete .symbol {
  background: #36b189;
  border: 1px solid #36b189;
}
.step.isComplete .symbol .icon {
  display: inline-block !important;
}
.step.isComplete .symbol .number {
  display: none;
}