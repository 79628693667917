/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   FRONT FOOTER
   =================================================================== */
.base {
  margin-top: 50px;
}
.base .action {
  height: 200px;
  background-color: rgba(23, 97, 166, 0.1);
  position: relative;
  display: flex;
  align-items: center;
}
.base .action .button {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.base .action .tagline {
  width: 100%;
  text-align: center;
  padding: 0 20px;
  font-size: 30px;
  line-height: 34px;
  font-weight: 200;
  padding-top: 20px;
}
.base .info {
  background-color: #3a4d60;
  color: #ffffff;
  padding: 70px 0 50px;
}
.base .info .logo {
  text-align: center;
}
.base .info .social {
  text-align: center;
  margin-top: 50px;
}
.base .info .social .button {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.base .info .social .button .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.base .info .social .button .icon svg {
  fill: #ffffff;
  transition: fill 0.2s ease;
}
.base .info .social .button .icon svg {
  width: 32px;
  height: 32px;
}
.base .info .social .button:hover {
  background-color: #ffffff;
}
.base .info .social .button:hover.facebook .icon svg {
  fill: #3b5999;
  transition: fill 0.2s ease;
}
.base .info .social .button:hover.twitter .icon svg {
  fill: #2aa3ef;
  transition: fill 0.2s ease;
}
.base .info .social .button:hover.email .icon svg {
  fill: #3a4d60;
  transition: fill 0.2s ease;
}
.base .copy {
  text-align: center;
  font-size: 16px;
  margin-top: 100px;
}
.base .copy .silent {
  font-size: 14px;
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.2);
}