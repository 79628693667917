/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
/* ===================================================================
   PAGE LEAD
   =================================================================== */
.base {
  margin-bottom: 20px;
  border-radius: 3px;
}
.base .inputWrapper {
  display: inline-block;
  vertical-align: top;
  width: 40%;
  margin-right: 5px;
}
.base .button {
  vertical-align: top;
}
.base.noSpacing {
  margin-bottom: 0;
}
.base.alignRight {
  text-align: right;
}
@media (max-width: 599px) {
  .base {
    margin-bottom: 15px;
  }
  .base .inputWrapper {
    display: block;
    width: auto;
    margin-right: 0;
    margin-bottom: 5px;
  }
  .base .button {
    display: block;
    width: 100%;
  }
}