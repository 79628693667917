/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eaf0f5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.label {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  font-weight: 700;
  color: rgba(23, 97, 166, 0.3);
  font-family: "Catamaran", sans-serif;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.logo {
  width: 50px;
  fill: rgba(23, 97, 166, 0.1);
}

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.dots .dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(23, 97, 166, 0.8);
  animation: pulse 1s infinite;
}
.dots .dot:nth-child(2) {
  animation-delay: 0.2s;
}
.dots .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}