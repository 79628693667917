/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.base {
  margin: 5vh 0 30vh;
  line-height: 1.3;
}
.base .messageOver {
  font-size: 30px;
  text-align: center;
  margin-bottom: 80px;
  padding: 0 30px;
}
.base .messageUnder {
  font-size: 36px;
  text-align: center;
  margin-top: 80px;
  padding: 0 30px;
}
.base .messageUnder u {
  text-decoration-line: underline;
  text-decoration-color: #d04b67;
  text-underline-offset: 5px;
}
.base .imageList {
  margin: 0 auto;
  width: 70%;
}
.base .imageList .image {
  border-radius: 15px;
  overflow: hidden;
  aspect-ratio: 1;
}
.base .imageList .image img {
  width: 100%;
}
.base .imageList .image.counter {
  background-color: rgba(23, 97, 166, 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  color: #1761a6;
}
@media (max-width: 599px) {
  .base .messageOver {
    font-size: 23px;
    margin-bottom: 50px;
  }
  .base .messageUnder {
    font-size: 25px;
    margin-top: 50px;
  }
  .base .imageList {
    width: 80%;
  }
  .base .imageList .image.counter {
    font-size: 20px;
  }
}