/* ===================================================================
   SETTINGS
   =================================================================== */
/* ===================================================================
   MIXINS
   =================================================================== */
.printable {
  color: #000000 !important;
}
.printable .page {
  position: relative;
  padding: 2rem;
  page-break-after: always;
  min-height: 99.99vh;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}
.printable .page:last-child {
  page-break-after: auto;
}
@media print {
  .printable .page {
    border: none;
  }
}